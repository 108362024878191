<template>
  <el-main class="p15 pt0">
    <div class="pt20" style="text-align: left">
      <el-form :inline="true" :model="cListPage" class="demo-form-inline">
        <el-form-item>
          <el-input
            placeholder="类型名称"
            v-model="cListPage.keyword"
            clearable
            size="medium"
            @clear="searchCList"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="medium" type="primary" @click="searchCList"
            >&emsp;查询&emsp;</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="createPopover"
          >
            <div class="p15">
              <el-form
                :model="cListForm"
                ref="cListForm"
                label-width="100px"
                class="demo-dForm"
                :rules="cListRule"
              >
                <el-form-item :label="'分类名称'" prop="categoryName" required>
                  <div style="width: 80%">
                    <el-input v-model="cListForm.categoryName"></el-input>
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createPopover = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="createCListItem"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button slot="reference" type="success" size="medium"
              >创建分类</el-button
            >
          </el-popover>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-row class="box search mt20">
      <el-form :inline="true" :model="cListPage" class="demo-form-inline">
        <el-col :span="4" class="mr10">
          <el-form-item size="medium">
            <el-input
              placeholder="类型名称"
              v-model="cListPage.keyword"
              clearable
              class="input-with-select"
              @clear="searchCList"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <div class="tl mr10 df" style="justify-content: flex-start">
          <el-button
            type="primary"
            style="height: 36px; margin-right: 10px"
            @click="searchCList"
            class="general_bgc general_border cfff"
            >&emsp;查询&emsp;</el-button
          >
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="createPopover"
          >
            <div class="p15">
              <el-form
                :model="cListForm"
                ref="cListForm"
                label-width="100px"
                class="demo-dForm"
                :rules="cListRule"
              >
                <el-form-item :label="'分类名称'" prop="categoryName" required>
                  <div style="width: 80%">
                    <el-input v-model="cListForm.categoryName"></el-input>
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createPopover = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="createCListItem"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button
              slot="reference"
              type="success"
              style="height: 36px; margin-right: 10px"
              >创建分类</el-button
            >
          </el-popover>
        </div>
      </el-form>
    </el-row> -->
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column label="序号" type="index" width="50" align="center">
      </el-table-column>
      <el-table-column
        prop="categoryName"
        label="类型名称"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="!scope.row.isEdit">{{ scope.row.categoryName }}</div>
          <div v-else>
            <el-form
              :model="cListEditForm"
              ref="cListEditForm"
              label-width="0px"
              class="demo-dForm"
              :rules="cListRule"
            >
              <el-form-item prop="categoryName" required>
                <el-input v-model="cListEditForm.categoryName"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="createdTime"
        label="创建时间"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.createdTime.replace("T", " ") }}
        </template>
      </el-table-column>
      <el-table-column label="查看详情" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            class="disib"
            size="mini"
            @click="gotoNextLayer(scope.row, 1)"
            >详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="360" align="center">
        <template slot-scope="scope">
          <div class="btn-container jcc df">
            <div class="btn-box df">
              <el-button
                type="text"
                :class="scope.row.isEdit ? 'disib c67c' : 'disib'"
                size="mini"
                @click="cListEdit(scope.row)"
                >{{ scope.row.isEdit ? "完成" : "修改" }}</el-button
              >
              <el-popover
                placement="bottom"
                width="200"
                trigger="click"
                v-model="scope.row.isDel"
              >
                <div class="p15">
                  <div class="tc p10">是否删除该分类?</div>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="scope.row.isDel = false"
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="delClistItem(scope.row)"
                      >确定</el-button
                    >
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="text"
                  class="disib red"
                  size="mini"
                  >删除</el-button
                >
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="i-page disib mt20">
      <el-pagination
        background
        :page-size="10"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="cListPage.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </el-main>
</template>
<script>
import api from "@/util/extra-api";

export default {
  data() {
    return {
      cListPage: {
        type: "productLine",
        keyword: "",
        page: 1,
        limit: 10,
      },
      createPopover: false,
      cListForm: {
        categoryName: "",
        categoryType: "productLine",
      },
      cListEditForm: {
        categoryName: "",
        categoryType: "productLine",
      },
      tableData: [],
      cListRule: {
        categoryName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
      },
      total: 0,
    };
  },
  props: {
    companyId: Number,
  },
  mounted() {
    this.getCList();
  },
  methods: {
    pageChange(e) {
      this.cListPage.page = e;
      this.getCList();
    },
    getCList() {
      api
        .get("/v1/pc/productionLine/cList", {
          companyId: this.companyId,
          ...this.cListPage,
        })
        .then((res) => {
          if (res.code === 200) {
            this.tableData = res.data.map((v) => {
              return {
                ...v,
                isEdit: false,
                isDel: false,
              };
            });
            this.total = res.total;
          }
        });
    },
    delClistItem(row) {
      api.delete(`/v1/pc/productionLine/cDelete/${row.id}`).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.cListPage.page = 1;
          this.getCList();
        }
      });
    },
    cListEdit(row) {
      if (!row.isEdit) {
        if (this.tableData.some((v) => v.isEdit)) {
          this.tableData
            .filter((v) => v.isEdit)
            .forEach((v) => {
              v.isEdit = false;
            });
        }
        this.cListEditForm.categoryName = row.categoryName;
        row.isEdit = true;
      } else {
        this.$refs.cListEditForm.validate((valid) => {
          if (valid) {
            api
              .post(
                `/v1/pc/productionLine/cUpdate?companyId=${this.companyId}`,
                {
                  ...this.cListEditForm,
                  id: row.id,
                }
              )
              .then((res) => {
                if (res.code === 200) {
                  this.$message({
                    message: "修改成功",
                    type: "success",
                  });
                  this.getCList();
                }
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    gotoNextLayer(row, layer) {
      this.$emit("layerChange", row.id, layer);
    },
    createCListItem() {
      this.$refs.cListForm.validate((valid) => {
        if (valid) {
          api
            .post(
              `/v1/pc/productionLine/cSave?companyId=${this.companyId}`,
              this.cListForm
            )
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });
                this.cListPage.page = 1;
                this.getCList();
                this.createPopover = false;
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    searchCList() {
      this.cListPage.page = 1;
      this.getCList();
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  .btn-box {
    justify-content: space-around;
    width: 120px;
  }
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}
</style>


