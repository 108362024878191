<template>
  <el-main class="p15 pt0">
    <div class="pt20" style="text-align: left">
      <el-form :inline="true" :model="productLinePage" class="demo-form-inline">
        <el-form-item>
          <el-input
            placeholder="食品加工名"
            v-model="productLinePage.keyword"
            clearable
            @clear="searchProductLine"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchProductLine" size="medium"
            >&emsp;查询&emsp;</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="createPopover"
          >
            <div class="p15">
              <el-form
                :model="pLineEditForm"
                ref="pLineEditForm"
                label-width="100px"
                class="demo-dForm"
                :rules="cListRule"
              >
                <el-form-item label="产品名" prop="name" required>
                  <div style="width: 80%">
                    <el-input v-model="pLineEditForm.name"></el-input>
                  </div>
                </el-form-item>
              </el-form>
              <el-form-item size="medium" label="是否为半成品">
                <el-switch
                  v-model="pLineEditForm.noProduction"
                  active-text="半成品"
                  inactive-text="制成品"
                >
                </el-switch>
              </el-form-item>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createPopover = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="createProductLineItem"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button slot="reference" type="success" size="medium"
              >创建产品线</el-button
            >
          </el-popover>
        </el-form-item>
        <el-form-item>
          <el-switch
            v-model="noProduction"
            active-text="全部"
            inactive-text="仅半成品"
            @change="switchChange"
          >
          </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-row class="box search mt20">
      <el-form :inline="true" :model="productLinePage" class="demo-form-inline">
        <el-col :span="4" class="mr10">
          <el-form-item size="medium">
            <el-input
              placeholder="食品加工名"
              v-model="productLinePage.keyword"
              clearable
              class="input-with-select"
              @clear="searchProductLine"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <div class="tl df" style="justify-content: flex-start">
          <el-button
            type="primary"
            @click="searchProductLine"
            style="height: 36px; margin-right: 10px"
            class="general_bgc general_border cfff"
            >&emsp;查询&emsp;</el-button
          >
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="createPopover"
          >
            <div class="p15">
              <el-form
                :model="pLineEditForm"
                ref="pLineEditForm"
                label-width="100px"
                class="demo-dForm"
                :rules="cListRule"
              >
                <el-form-item label="产品名" prop="name" required>
                  <div style="width: 80%">
                    <el-input v-model="pLineEditForm.name"></el-input>
                  </div>
                </el-form-item>
              </el-form>
              <el-form-item size="medium" label="是否为半成品">
                <el-switch
                  v-model="pLineEditForm.noProduction"
                  active-text="半成品"
                  inactive-text="制成品"
                >
                </el-switch>
              </el-form-item>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createPopover = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="createProductLineItem"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button
              slot="reference"
              type="success"
              style="height: 36px; margin-right: 10px"
              >创建产品线</el-button
            >
          </el-popover>
          <div class="df aic jcc" style="height: 36px">
            <el-switch
              v-model="noProduction"
              active-text="全部"
              inactive-text="仅半成品"
              @change="switchChange"
            >
            </el-switch>
          </div>
        </div>
      </el-form>
    </el-row> -->
    <el-table :data="ProductLineData" style="width: 100%" border>
      <el-table-column label="序号" type="index" width="50" align="center">
      </el-table-column>
      <el-table-column
        prop="name"
        label="食品加工名"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="!scope.row.isEdit">{{ scope.row.name }}</div>
          <div v-else>
            <el-form
              :model="pLineEditForm"
              ref="pLineEditForm"
              label-width="0px"
              class="demo-dForm"
              :rules="cListRule"
            >
              <el-form-item prop="name" required>
                <el-input v-model="pLineEditForm.name"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="theoreticalOutput"
        label="理论产量"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="!scope.row.isEdit">
            {{
              scope.row.theoreticalOutput
                ? scope.row.theoreticalOutput +
                  (scope.row.unit ? scope.row.unit : "kg")
                : "未配置"
            }}
          </div>
          <div v-else class="df">
            <div style="flex: 1">
              <el-form
                :model="pLineEditForm"
                ref="pLineEditForm1"
                label-width="0px"
                class="demo-dForm"
                :rules="cListRule"
              >
                <el-form-item prop="theoreticalOutput" required>
                  <el-input
                    v-model="pLineEditForm.theoreticalOutput"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div style="flex: 0 0 100px" class="df jcc aic">
              <el-select v-model="pLineEditForm.unit" placeholder="请选择">
                <el-option
                  v-for="item in unitList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="noProduction"
        label="是否为半成品"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="!scope.row.isEdit">
            <div v-if="scope.row.noProduction">半成品</div>
          </div>
          <div v-else>
            <el-switch
              v-model="scope.row.noProduction"
              active-text="半成品"
              inactive-text="制成品"
              @change="noProductionChange(scope.row)"
            >
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="mandatory"
        label="HACCP是否强制按工序顺序"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <div @click.capture="pListId = scope.row.id">
            <el-switch v-model="scope.row.mandatory" @change="mandatoryChange">
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="生产管理" width="280" align="center">
        <template slot-scope="scope">
          <div class="btn-container jcc df">
            <div class="btn-box df" style="width: 280px">
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="gotoNextLayer(scope.row, 2, 0)"
                >投料模板</el-button
              >
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="gotoNextLayer(scope.row, 2, 1)"
                >工序模板</el-button
              >
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="
                  gotoNextLayer(scope.row, 2, 2, {
                    theoreticalOutput: scope.row.theoreticalOutput,
                    mandatory: scope.row.mandatory,
                    unit: scope.row.unit,
                    noProduction: scope.row.noProduction,
                  })
                "
                >批次生产</el-button
              >
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="getProportion(scope.row.id)"
                >物料平衡</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240" align="center">
        <template slot-scope="scope">
          <div class="btn-container jcc df">
            <div class="btn-box df">
              <el-button
                v-if="!scope.row.isEdit"
                type="text"
                class="disib"
                size="mini"
                @click="pLineEdit(scope.row)"
                >修改</el-button
              >
              <el-button
                v-else
                type="text"
                class="disib c67c"
                size="mini"
                @click="pLineEdit(scope.row)"
                >完成</el-button
              >
              <el-popover
                v-if="!scope.row.isEdit"
                placement="bottom"
                width="200"
                trigger="click"
                v-model="scope.row.isDel"
              >
                <div class="p15">
                  <div class="tc p10">是否删除该产品?</div>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="scope.row.isDel = false"
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="delPlistItem(scope.row)"
                      >确定</el-button
                    >
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="text"
                  class="disib red"
                  size="mini"
                  >删除</el-button
                >
              </el-popover>
              <el-button
                v-else
                type="text"
                class="'disib"
                size="mini"
                @click="
                  scope.row.isEdit = false;
                  pLineEditForm.name = '';
                  pLineEditForm.noProduction = false;
                "
                >取消</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="i-page disib mt20">
      <el-pagination
        background
        :page-size="10"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="productLinePage.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="物料平衡"
      :visible.sync="showProportionOfData"
      width="40%"
    >
      <el-date-picker
        v-model="dataValue1"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        @change="pickerData"
        style="margin-bottom: 5px"
      >
      </el-date-picker>
      <el-table :data="proportionOfDataList" border fit>
        <el-table-column prop="name" label="原料名称" align="center">
        </el-table-column>
        <el-table-column prop="proportion" label="产出投料比" align="center">
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-main>
</template>
<script>
import api from "@/util/extra-api";
import dayjs from "dayjs";

export default {
  data() {
    return {
      ProductLineData: [],
      createPopover: false,
      total: 0,
      productLinePage: {
        categoryId: null,
        keyword: "",
        page: 1,
        limit: 10,
      },
      pLineEditForm: {
        name: "",
        theoreticalOutput: "",
        unit: "",
        categoryType: "productLine",
        noProduction: false,
      },
      templateData: [],
      cListRule: {
        name: [{ required: true, message: "请输入产品名", trigger: "blur" }],
        theoreticalOutput: [
          { required: true, message: "请输入理论产量", trigger: "blur" },
        ],
      },
      unitList: [],
      noProduction: true,
      startTime: "",
      endTime: "",
      // dataValue: [],
      dataValue1: [0, 0],
      showProportionOfData: false,
      proportionOfDataList: [],
    };
  },
  mounted() {
    this.productLinePage.categoryId = this.categoryId;
    this.getProductLine();
  },
  props: {
    companyId: Number,
    categoryId: Number,
  },
  methods: {
    getProportion(id) {
      this.checkedId = id;
      let data = dayjs().format("YYYY-MM-DD");
      this.dataValue1 = [
        dayjs(data).subtract(1, "week").format("YYYY-MM-DD"),
        data,
      ];
      this.proportionOfData();
    },
    // /v1/wx/productionLine/line/proportionOfData
    pickerData(value) {
      this.dataValue1 = [
        dayjs(value[0]).format("YYYY-MM-DD"),
        dayjs(value[1]).format("YYYY-MM-DD"),
      ];
      // console.log(this.dataValue1, 123);
      this.proportionOfData();
    },
    proportionOfData() {
      api
        .get("/v1/wx/productionLine/line/proportionOfData", {
          lineId: this.checkedId,
          startTime: this.dataValue1[0],
          endTime: this.dataValue1[1],
        })
        .then((res) => {
          if (res.code === 200) {
            this.proportionOfDataList = res.data;
            this.showProportionOfData = true;
          }
        });
    },
    noProductionChange(e) {
      this.pLineEditForm.noProduction = e.noProduction;
    },
    switchChange() {
      this.productLinePage.page = 1;
      this.getProductLine();
    },
    pageChange(e) {
      this.productLinePage.page = e;
      this.getProductLine();
    },
    getProductLine() {
      let requestData = {
        ...this.productLinePage,
      };
      if (!this.noProduction) {
        requestData.noProduction = true;
      }
      api
        .get(
          `/v1/pc/productionLine/pList?companyId=${this.companyId}`,
          requestData
        )
        .then((res) => {
          if (res.code === 200) {
            this.ProductLineData = res.data.map((v) => {
              return {
                ...v,
                isEdit: false,
                isDel: false,
              };
            });
            this.total = res.total;
          }
        });
    },
    searchProductLine() {
      this.productLinePage.page = 1;
      this.getProductLine();
    },
    createProductLineItem() {
      this.$refs.pLineEditForm.validate((valid) => {
        if (valid) {
          api
            .post(`/v1/pc/productionLine/pSave?companyId=${this.companyId}`, {
              categoryId: this.categoryId,
              name: this.pLineEditForm.name,
              noProduction: this.pLineEditForm.noProduction,
            })
            .then((res) => {
              if (res.code === 200) {
                this.$message({
                  message: "新增成功",
                  type: "success",
                });

                this.createPopover = false;
                this.productLinePage.keyword = "";
                this.productLinePage.page = 1;
                this.pLineEditForm.noProduction = false;
                // this.pLineEditForm.name = "";
                this.$refs.pLineEditForm.resetFields();
                this.getProductLine();
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    pLineEdit(row) {
      if (!row.isEdit) {
        if (this.ProductLineData.some((v) => v.isEdit)) {
          this.ProductLineData.filter((v) => v.isEdit).forEach((v) => {
            v.isEdit = false;
          });
        }
        this.pLineEditForm.unit = row.unit ? row.unit : "kg";
        this.pLineEditForm.theoreticalOutput = row.theoreticalOutput;
        this.pLineEditForm.name = row.name;
        // console.log(row.noProduction,'测试');
        this.pLineEditForm.noProduction = row.noProduction;
        row.isEdit = true;
      } else {
        this.$refs.pLineEditForm.validate((valid) => {
          if (valid) {
            this.$refs.pLineEditForm1.validate((valid1) => {
              if (valid1) {
                api
                  .post(`/v1/pc/productionLine/pUpdate`, {
                    id: row.id,
                    name: this.pLineEditForm.name,
                    theoreticalOutput: this.pLineEditForm.theoreticalOutput,
                    unit: this.pLineEditForm.unit,
                    noProduction: this.pLineEditForm.noProduction,
                  })
                  .then((res) => {
                    if (res.code === 200) {
                      this.$message({
                        message: "修改成功",
                        type: "success",
                      });
                      this.getProductLine();
                    }
                  });
              } else {
                console.log("error submit!!");
                return false;
              }
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    delPlistItem(row) {
      api
        .post(`/v1/pc/productionLine/pDelete`, {
          id: row.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.productLinePage.keyword = "";
            this.productLinePage.page = 1;
            this.getProductLine();
          }
        });
    },
    mandatoryChange(status) {
      api
        .post(`/v1/pc/productionLine/strongCheck?companyId=${this.companyId}`, {
          type: 0,
          id: this.pListId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.pListId = null;
            this.getProductLine();
          }
        });
    },
    gotoNextLayer(row, layer, type, data) {
      this.$emit("layerChange", row.id, layer, type, data);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  .btn-box {
    justify-content: space-around;
    width: 120px;
  }
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}
</style>


