<template>
  <el-main class="p15 pt0">
    <div class="pt20" style="text-align: left">
      <el-form :inline="true" :model="haccpListPage" class="demo-form-inline">
        <el-form-item>
          <el-input
            placeholder="工序名称"
            v-model="haccpListPage.keyword"
            clearable
            @clear="searchHaccpList"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="searchHaccpList"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="createPopover"
          >
            <div class="p15">
              <el-form
                ref="createHaccpForm"
                label-width="140px"
                class="demo-dForm"
              >
                <el-form-item :label="'工序名称'" prop="templateName">
                  <div style="width: 60%">
                    <el-input v-model="createHaccpForm.templateName"></el-input>
                  </div>
                </el-form-item>
                <el-form-item :label="'是否可多次提交'">
                  <div style="width: 60%">
                    <el-switch v-model="createHaccpForm.type"> </el-switch>
                  </div>
                </el-form-item>
                <div
                  class="pt10"
                  v-for="(item, index) in createHaccpForm.detail"
                  :key="index"
                  style="position: relative"
                >
                  <div
                    style="
                      position: absolute;
                      right: 10px;
                      top: 10px;
                      z-index: 999;
                    "
                  >
                    <el-button
                      type="text"
                      class="disib red"
                      size="mini"
                      @click="delCreateHaccp(index)"
                      >移除</el-button
                    >
                  </div>
                  <el-form-item :label="'信息类型'">
                    <div
                      style="width: 60%"
                      @click.capture="haccpTypeChangeIndex = index"
                    >
                      <el-select
                        v-model="item.value"
                        placeholder="请选择"
                        @change="haccpTypeChange"
                      >
                        <el-option
                          v-for="item in haccpType"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item :label="'自定义名称'">
                    <div style="width: 60%">
                      <el-input v-model="item.name"></el-input>
                    </div>
                  </el-form-item>
                  <el-form-item :label="'是否对外展示'">
                    <div style="width: 60%">
                      <el-switch v-model="item.isShow"> </el-switch>
                    </div>
                  </el-form-item>
                </div>

                <el-form-item label-width="0px">
                  <div class="df aic jcc">
                    <el-button
                      type="primary"
                      class="general_bgc general_border cfff"
                      icon="el-icon-plus"
                      size="mini"
                      @click="
                        createHaccpForm.detail.push({
                          name: '',
                          type: null,
                          value: '',
                          isShow: false,
                        })
                      "
                      >新增采集信息</el-button
                    >
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createPopover = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="createHaccpItem"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button slot="reference" type="success" size="medium"
              >创建模板</el-button
            >
          </el-popover>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-row class="box search mt20">
      <el-form :inline="true" :model="haccpListPage" class="demo-form-inline">
        <el-col :span="4" class="mr10">
          <el-form-item size="medium">
            <el-input
              placeholder="工序名称"
              v-model="haccpListPage.keyword"
              clearable
              class="input-with-select"
              @clear="searchHaccpList"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <div class="df">
          <el-button
            type="primary"
            style="height: 36px"
            @click="searchHaccpList"
            class="general_bgc general_border cfff mr10"
            >查询</el-button
          >
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="createPopover"
          >
            <div class="p15">
              <el-form
                ref="createHaccpForm"
                label-width="140px"
                class="demo-dForm"
              >
                <el-form-item :label="'工序名称'" prop="templateName">
                  <div style="width: 60%">
                    <el-input v-model="createHaccpForm.templateName"></el-input>
                  </div>
                </el-form-item>
                <el-form-item :label="'是否可多次提交'">
                  <div style="width: 60%">
                    <el-switch v-model="createHaccpForm.type"> </el-switch>
                  </div>
                </el-form-item>
                <div
                  class="pt10"
                  v-for="(item, index) in createHaccpForm.detail"
                  :key="index"
                  style="position: relative"
                >
                  <div
                    style="
                      position: absolute;
                      right: 10px;
                      top: 10px;
                      z-index: 999;
                    "
                  >
                    <el-button
                      type="text"
                      class="disib red"
                      size="mini"
                      @click="delCreateHaccp(index)"
                      >移除</el-button
                    >
                  </div>
                  <el-form-item :label="'信息类型'">
                    <div
                      style="width: 60%"
                      @click.capture="haccpTypeChangeIndex = index"
                    >
                      <el-select
                        v-model="item.value"
                        placeholder="请选择"
                        @change="haccpTypeChange"
                      >
                        <el-option
                          v-for="item in haccpType"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item :label="'自定义名称'">
                    <div style="width: 60%">
                      <el-input v-model="item.name"></el-input>
                    </div>
                  </el-form-item>
                  <el-form-item :label="'是否对外展示'">
                    <div style="width: 60%">
                      <el-switch v-model="item.isShow"> </el-switch>
                    </div>
                  </el-form-item>
                </div>

                <el-form-item label-width="0px">
                  <div class="df aic jcc">
                    <el-button
                      type="primary"
                      class="general_bgc general_border cfff"
                      icon="el-icon-plus"
                      size="mini"
                      @click="
                        createHaccpForm.detail.push({
                          name: '',
                          type: null,
                          value: '',
                          isShow: false,
                        })
                      "
                      >新增采集信息</el-button
                    >
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createPopover = false"
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="createHaccpItem"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button
              slot="reference"
              style="height: 36px"
              class="mr10"
              type="success"
              >创建模板</el-button
            >
          </el-popover>
        </div>
      </el-form>
    </el-row> -->
    <el-table :data="haccpList" style="width: 100%" border>
      <el-table-column label="序号" type="index" width="50" align="center">
      </el-table-column>
      <el-table-column
        prop="templateName"
        label="工序名称"
        width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.createTime.replace("T", " ") }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <div class="btn-container jcc df">
            <div class="btn-box df">
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="openHaccpDailog(scope.row)"
                >修改</el-button
              >
              <el-popover
                v-if="!scope.row.isEdit"
                placement="bottom"
                width="200"
                trigger="click"
                v-model="scope.row.isDel"
              >
                <div class="p15">
                  <div class="tc p10">是否删除该模板?</div>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="scope.row.isDel = false"
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="delHaccpItem(scope.row)"
                      >确定</el-button
                    >
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="text"
                  class="disib red"
                  size="mini"
                  >删除</el-button
                >
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="i-page disib mt20">
      <el-pagination
        background
        :page-size="10"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="haccpListPage.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="模板管理" :visible.sync="haccpVisible">
      <div>
        <el-form
          :model="haccpData"
          ref="haccpData"
          label-width="140px"
          class="demo-dForm"
        >
          <el-form-item :label="'工序名称'" prop="templateName">
            <div style="width: 60%">
              <el-input
                v-model="haccpData.templateName"
                :disabled="!haccpData.isEdit"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item :label="'是否可多次提交'">
            <div style="width: 60%; text-align: left">
              <el-switch v-model="haccpData.type" :disabled="!haccpData.isEdit">
              </el-switch>
            </div>
          </el-form-item>
          <div
            class="pt10"
            v-for="(item, index) in haccpData.haccpDetailList"
            :key="index"
            style="position: relative"
          >
            <div
              v-if="haccpData.isEdit"
              style="position: absolute; right: 10px; top: 10px; z-index: 999"
            >
              <el-button
                type="text"
                class="disib red"
                size="mini"
                @click="delCreateHaccp(index)"
                >移除</el-button
              >
            </div>
            <el-form-item :label="'信息类型'">
              <div
                style="width: 60%; text-align: left"
                @click.capture="haccpTypeChangeIndex = index"
              >
                <el-select
                  v-model="item.value"
                  placeholder="请选择"
                  @change="haccpTypeUpdateChange"
                  :disabled="!haccpData.isEdit"
                >
                  <el-option
                    v-for="item in haccpType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item :label="'自定义名称'">
              <div style="width: 60%">
                <el-input
                  v-model="item.name"
                  :disabled="!haccpData.isEdit"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item :label="'是否对外展示'">
              <div style="width: 60%; text-align: left">
                <el-switch v-model="item.isShow" :disabled="!haccpData.isEdit">
                </el-switch>
              </div>
            </el-form-item>
          </div>

          <el-form-item label-width="0px" v-if="haccpData.isEdit">
            <div class="df aic jcc">
              <el-button
                type="primary"
                class="general_bgc general_border cfff"
                icon="el-icon-plus"
                size="mini"
                @click="
                  haccpData.haccpDetailList.push({
                    name: '',
                    type: null,
                    value: '',
                    isShow: false,
                  })
                "
                >新增采集信息</el-button
              >
            </div>
          </el-form-item>
          <el-form-item label-width="0px">
            <div class="df aic jcc">
              <el-button
                type="primary"
                size="medium"
                v-if="!haccpData.isEdit"
                @click="haccpData.isEdit = true"
                >更新</el-button
              >
              <template v-else>
                <el-button type="success" size="medium" @click="haccpUpdate()"
                  >完成</el-button
                >
                <el-button
                  type="info"
                  size="medium"
                  @click="haccpData.isEdit = false"
                  >取消</el-button
                >
              </template>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import api from "@/util/extra-api";
export default {
  data() {
    return {
      haccpList: [],
      haccpListPage: {
        productionLineId: null,
        keyword: "",
        page: 1,
        limit: 10,
      },
      total: 0,
      createPopover: false,
      createHaccpForm: {
        templateName: "",
        type: false,
        detail: [],
      },
      haccpType: [],
      haccpTypeChangeIndex: null,
      haccpVisible: false,
      haccpData: {},
    };
  },
  props: {
    companyId: Number,
    productionLineId: Number,
  },
  mounted() {
    this.haccpListPage.productionLineId = this.productionLineId;
    this.getHaccpList();
    this.getHaccpType();
  },
  methods: {
    delHaccpItem(row) {
      api
        .post(`/v1/pc/productionLine/hDelete?companyId=${this.companyId}`, {
          id: row.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.haccpListPage.keyword = "";
            this.searchHaccpList();
          }
        });
    },
    pageChange(e) {
      this.haccpListPage.page = e;
      this.getHaccpList();
    },
    getHaccpType() {
      api.get(`/v1/wx/dic/data?typeCode=haccp_type`).then((res) => {
        if (res.code === 200) {
          this.haccpType = res.data.map((v) => {
            return {
              ...v,
              type: v.value,
              value: v.label,
            };
          });
        }
      });
    },
    getHaccpList() {
      this.haccpListPage.productionLineId = this.productionLineId;
      api
        .get(
          `/v1/pc/productionLine/hList?companyId=${this.companyId}`,
          this.haccpListPage
        )
        .then((res) => {
          if (res.code === 200) {
            this.haccpList = res.data;
            this.total = res.total;
          }
        });
    },
    searchHaccpList() {
      this.haccpListPage.page = 1;
      this.getHaccpList();
    },
    delCreateHaccp(index) {
      this.createHaccpForm.detail = this.createHaccpForm.detail.filter(
        (v, i) => i !== index
      );
    },
    haccpTypeChange(e) {
      this.createHaccpForm.detail[this.haccpTypeChangeIndex].type =
        this.haccpType.filter((v) => v.label === e)[0].type;
      // console.log(e, this.createHaccpForm.detail[this.haccpTypeChangeIndex]);
    },
    haccpTypeUpdateChange(e) {
      this.haccpData.haccpDetailList[this.haccpTypeChangeIndex].type =
        this.haccpType.filter((v) => v.label === e)[0].type;
    },
    createHaccpItem() {
      let data = {
        detail: [...this.createHaccpForm.detail],
        templateName: this.createHaccpForm.templateName,
        type: this.createHaccpForm.type ? 1 : 0,
        productionLineId: this.productionLineId,
      };
      // console.log(data);
      api
        .post(`/v1/pc/productionLine/hSave?companyId=${this.companyId}`, data)
        .then((res) => {
          if (res.code === 200) {
            this.createPopover = false;
            this.haccpListPage.keyword = "";
            this.searchHaccpList();
            this.createHaccpForm = {
              templateName: "",
              type: false,
              detail: [],
            };
          }
        });
    },
    openHaccpDailog(row) {
      this.haccpVisible = true;
      api
        .get(`/v1/pc/productionLine/hDetail?companyId=${this.companyId}`, {
          productionLineId: this.productionLineId,
          templateId: row.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.haccpData = {
              ...res.data,
              isEdit: false,
            };
          }
        });
    },
    haccpUpdate() {
      // haccpData
      // console.log(this.haccpData);
      let data = {
        id: this.haccpData.id,
        productionLineId: this.productionLineId,
        templateName: this.haccpData.templateName,
        type: 0,
        detail: this.haccpData.haccpDetailList.map((v) => {
          let val = {
            value: v.value,
            type: v.type,
            name: v.name,
            isShow: v.isShow,
          };
          if (v.id) {
            val.id = v.id;
          }
          return v;
        }),
      };
      // this.haccpData.isEdit = false;
      api
        .post(`/v1/pc/productionLine/hUpdate?companyId=${this.companyId}`, data)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "更新成功",
              type: "success",
            });
            this.haccpData.isEdit = false;
          }
        });
      // /v1/pc/productionLine/hUpdate
    },
    editBatch(row) {
      this.batchList.forEach((v) => {
        if (v.isEdit) {
          v.isEdit = false;
        }
      });
      row.isEdit = true;
      this.batchEditForm.orderNumber = row.name;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  .btn-box {
    justify-content: space-around;
    width: 120px;
  }
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}
</style>


