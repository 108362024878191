<template>
  <div>
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="bindInfoPage" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="商品名称"
              v-model="bindInfoPage.keyword"
              clearable
              @clear="searchBindInfo"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="searchBindInfo" size="medium"
              >&emsp;查询&emsp;</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="success" size="medium" @click="openGoodsDailog"
              >绑定商品</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="bindInfoPage" class="demo-form-inline">
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="商品名称"
                v-model="bindInfoPage.keyword"
                clearable
                class="input-with-select"
                @clear="searchBindInfo"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <div class="df">
            <el-button
              style="height: 36px"
              type="primary"
              @click="searchBindInfo"
              class="general_bgc general_border cfff mr10"
              >&emsp;查询&emsp;</el-button
            >
            <el-button
              type="success"
              class="mr10"
              style="height: 36px; margin-left: 0"
              @click="openGoodsDailog"
              >绑定商品</el-button
            >
          </div>
        </el-form>
      </el-row> -->
      <el-table :data="bindList" style="width: 100%" border>
        <el-table-column label="序号" type="index" width="50" align="center">
        </el-table-column>
        <el-table-column
          prop="goodsName"
          label="名称"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column label="分类" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.gtinInfo.goodsCategory }}
          </template>
        </el-table-column>
        <el-table-column label="规格" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.gtinInfo.spec }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          label="生产日期"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime.replace("T", " ") }}
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsWeight"
          label="产量"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.goodsWeight }}kg
          </template>
        </el-table-column>
        <el-table-column label="计划产量" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.value4 }}kg
          </template>
        </el-table-column>
        <el-table-column label="损耗量" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.value2 }}kg
          </template>
        </el-table-column>
        <el-table-column label="采样量" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.value3 }}kg
          </template>
        </el-table-column>
        <el-table-column label="物料平衡率" width="180" align="center">
          <template slot-scope="scope">
            {{ scope.row.balance * 100 }}%
          </template>
        </el-table-column>
        <el-table-column label="操作" width="360" align="center">
          <template slot-scope="scope">
            <div class="btn-container jcc df">
              <div class="btn-box df">
                <el-button
                  type="text"
                  :class="scope.row.isEdit ? 'disib c67c' : 'disib'"
                  size="mini"
                  @click="
                    proportionOfData(
                      scope.row.productionRecordId,
                      scope.row.finishedTag
                    )
                  "
                  >物料平衡</el-button
                >
                <el-button
                  type="text"
                  :class="scope.row.isEdit ? 'disib c67c' : 'disib'"
                  size="mini"
                  @click="cListEdit(scope.row)"
                  >打码</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page disib mt20">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="total"
          :current-page="bindInfoPage.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </el-main>
    <el-dialog title="选择商品" :visible.sync="goodsVisible" width="35%">
      <div class="" style="text-align: left">
        <el-form :inline="true" :model="goodsPage" class="demo-form-inline">
          <el-form-item>
            <el-input
              size="medium"
              v-model="goodsPage.keyword"
              style="width: 250px"
              placeholder="请输入商品编码或商品名称查询"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="searchGoods"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <div class="tl" style="margin-bottom: 8px">
        <el-input
          size="small"
          v-model="goodsPage.keyword"
          style="width: 250px"
          placeholder="请输入商品编码或商品名称查询"
        ></el-input>
        <el-button style="margin-left: 8px" size="small" @click="searchGoods"
          >查询</el-button
        >
      </div> -->
      <el-table
        :data="goodsInfo.data"
        border
        fit
        height="400"
        v-loading="goodsSearchLoading"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="进口/出口">
                <span>{{ props.row.goodsCategory }}</span>
              </el-form-item>
              <el-form-item label="生产商">
                <span>{{ props.row.manuName }}</span>
              </el-form-item>
              <el-form-item label="原产国">
                <span>{{ props.row.originCountryName }}</span>
              </el-form-item>
              <el-form-item label="商品类型">
                <span>{{ props.row.goodsType }}</span>
              </el-form-item>
              <el-form-item label="商品规格">
                <span>{{ props.row.spec }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          property="goodsName"
          label="商品名称"
          min-width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          property="code"
          label="商品编码"
          min-width="200"
          align="center"
        ></el-table-column>
        <el-table-column label="操作" width="180" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib orange"
              size="mini"
              @click="handleBind(scope.row)"
              >选择商品</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page disib mt20">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="goodsInfo.total"
          :current-page="isGoddsSearch ? goodsPage.page : goodsPageAdd.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="商品绑定" :visible.sync="goodsBindVisible" width="35%">
      <div class="gooods-dialog">
        <el-form
          ref="gooodsForm"
          :model="goodsForm"
          label-width="200px"
          :rules="goodsSubmitRule"
        >
          <el-form-item label="货品类型">
            <div style="width: 70%; text-align: left">
              <el-radio-group v-model="goodsForm.type" @change="unitChange">
                <el-radio-button label="规格货品"></el-radio-button>
                <el-radio-button label="散装货品"></el-radio-button>
              </el-radio-group>
            </div>
          </el-form-item>
          <template
            v-if="goodsForm.type === '规格货品' && !goodsWeightUnitList"
          >
            <el-form-item label="生产件数" prop="goodsNumber">
              <div style="width: 70%">
                <el-input
                  v-model="goodsForm.goodsNumber"
                  placeholder="生产件数"
                >
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="外箱每件所含单品量" prop="goodsUnit">
              <div style="width: 70%">
                <el-input
                  v-model="goodsForm.goodsUnit"
                  placeholder="外箱每件所含单品量"
                >
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="零散单品量">
              <div style="width: 70%">
                <el-input
                  v-model="goodsForm.goodsItem"
                  placeholder="零散单品量"
                >
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="独立销售单品净含量" prop="goodsWeightUnit">
              <div style="width: 70%">
                <el-input
                  v-model="goodsForm.goodsWeightUnit"
                  placeholder="请填写重量"
                >
                  <template slot="append">
                    <el-select
                      v-model="goodsForm.weightUnit"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in goodsWeightUnitListDefault"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-input>
              </div>
            </el-form-item>
          </template>
          <template
            v-else-if="goodsForm.type === '规格货品' && goodsWeightUnitList"
          >
            <el-form-item label="生产件数" prop="goodsSum">
              <div style="width: 70%">
                <el-input v-model="goodsForm.goodsSum" placeholder="生产件数">
                  <template slot="append">
                    <el-select
                      v-model="goodsForm.weightUnit"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in goodsWeightUnitList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-input>
              </div>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="重量" prop="goodsWeight">
              <div style="width: 70%">
                <el-input
                  v-model="goodsForm.goodsWeight"
                  placeholder="请填写重量"
                >
                  <template slot="append">
                    <el-select
                      v-model="goodsForm.weightUnit"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in goodsWeightUnitListDefault"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-input>
              </div>
            </el-form-item>
          </template>
          <el-form-item label="外销批号" prop="batchNumber">
            <div style="width: 70%">
              <el-input
                v-model="goodsForm.batchNumber"
                placeholder="外销批号"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="生产日期" prop="time">
            <div style="width: 70%; text-align: left">
              <el-date-picker
                v-model="goodsForm.time"
                @change="dateChange"
                type="date"
                placeholder="生产日期"
              >
              </el-date-picker>
            </div>
          </el-form-item>
          <el-form-item label="保质期" prop="qualityGuaranteePeriod">
            <div style="width: 70%">
              <el-input
                v-model="goodsForm.qualityGuaranteePeriod"
                placeholder="单位/天"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="绑码实景">
            <div style="width: 70%; text-align: left">
              <el-upload
                action="#"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleBindRemove"
                :http-request="
                  (params) => {
                    uploadBindFile(params);
                  }
                "
                :file-list="goodsForm.picUrl"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="终结本批">
            <div style="width: 70%; text-align: left">
              <el-radio-group v-model="goodsForm.isEnd">
                <el-radio-button label="不终结本批"></el-radio-button>
                <el-radio-button label="终结本批"></el-radio-button>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item>
            <div style="width: 70%; text-align: left">
              <el-button type="primary" @click="bindSubmit">提交</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      title="物料平衡"
      :visible.sync="showProportionOfData"
      width="40%"
    >
      <el-table :data="proportionOfDataList" border fit>
        <el-table-column prop="name" label="原料名称" align="center">
        </el-table-column>
        <el-table-column prop="proportion" label="产出投料比" align="center">
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/util/extra-api";
import noBaseExtraAxios from "@/util/no-base-extra-api";
import axios from "@/util/api";

export default {
  data() {
    return {
      bindInfoPage: {
        productionRecordId: null,
        keyword: "",
        page: 1,
        limit: 10,
      },
      goodsVisible: false,
      isGoddsSearch: false,
      goodsBindVisible: false,
      goodsInfo: {
        data: [],
        total: 0,
      },
      goodsPage: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      goodsPageAdd: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      goodsSearchLoading: false,
      bindList: [],
      total: 0,
      goodsForm: {
        batchNumber: null,
        type: "规格货品",
        goodsName: null,
        goodsId: null,
        code: null,
        time: "",
        goodsWeight: "",
        timeStr: "",
        qualityGuaranteePeriod: "",
        picUrl: [],
        isEnd: "不终结本批",
        goodsSum: "",
        goodsWeightUnit: "",
        goodsItem: "",
        goodsNumber: "",
        weightUnit: "kg",
        gtinInfo: null,
      },
      goodsWeightUnitList: null,
      goodsWeightUnitListDefault: [
        { label: "kg", value: "kg" },
        { label: "g", value: "g" },
      ],
      goodsSubmitRule: {
        goodsNumber: [
          { required: true, message: "请输入生产件数", trigger: "blur" },
        ],
        goodsWeightUnit: [
          {
            required: true,
            message: "请输入独立销售单品净含量",
            trigger: "blur",
          },
        ],
        goodsSum: [
          { required: true, message: "请输入生产件数", trigger: "blur" },
        ],
        goodsWeight: [
          { required: true, message: "请输入重量", trigger: "blur" },
        ],
        goodsUnit: [
          {
            required: true,
            message: "请输入外箱每件所含单品量",
            trigger: "blur",
          },
        ],
        batchNumber: [
          { required: true, message: "请输入外销批号", trigger: "blur" },
        ],
        time: [{ required: true, message: "请选择生产日期", trigger: "blur" }],
        qualityGuaranteePeriod: [
          { required: true, message: "请选择保质期", trigger: "blur" },
        ],
      },
      showProportionOfData: false,
      proportionOfDataList: [],
    };
  },
  props: {
    companyId: Number,
    productionRecordId: Number,
    productionLineId: Number,
  },
  mounted() {
    this.getBindInfo();
  },
  methods: {
    proportionOfData(recordId, finishedTag) {
      api
        .get(`/v1/wx/productionLine/record/proportionOfData`, {
          recordId,
          finishedTag,
        })
        .then((res) => {
          if (res.code === 200) {
            this.showProportionOfData = true;
            this.proportionOfDataList = res.data;
          }
        });
    },
    handlePictureCardPreview(file) {
      //查看图片
      // console.log(file, 1);
      window.open(file.url);
    },
    pictureCardPreview(url) {
      window.open(url);
    },
    pageChange(e) {
      if (!this.goodsVisible) {
        this.bindInfoPage = e;
        this.getBindInfo();
      } else {
        if (this.isGoddsSearch) {
          this.goodsPage.page = e;
        } else {
          this.goodsPageAdd = e;
        }
      }
    },
    getBindInfo() {
      if (!this.finishedTag) {
        api
          .get(`/v1/wx/productionLine/finishedTag`, {
            type: 0,
            productionRecordId: this.productionRecordId,
          })
          .then((res) => {
            if (res.code === 200) {
              this.finishedTag = res.data;
            }
          });
      }
      if (this.goodsVisible === false) {
        this.bindInfoPage.productionRecordId = this.productionRecordId;
        api
          .get(
            `/v1/pc/productionLine/bList?companyId=${this.companyId}`,
            this.bindInfoPage
          )
          .then((res) => {
            if (res.code === 200) {
              this.bindList = res.data.map((v) => {
                return {
                  ...v,
                  gtinInfo: JSON.parse(v.gtinInfo),
                };
              });
              this.total = res.total;
              if (this.isGoddsSearch) {
                this.goodsPage.page = res.total;
              } else {
                this.goodsPageAdd.page = res.total;
              }
            }
          });
      } else {
        this.goodsPageAdd.productionRecordId = this.productionRecordId;
        api
          .get(
            `/v1/pc/productionLine/bList?companyId=${this.companyId}`,
            this.goodsPageAdd
          )
          .then((res) => {
            if (res.code === 200) {
              this.goodsInfo.data = res.data.map((v) => {
                return {
                  ...v,
                  code: v.gtin,
                  goodsCategory: JSON.parse(v.gtinInfo).goodsCategory,
                  manuName: JSON.parse(v.gtinInfo).manuName,
                  originCountryName: JSON.parse(v.gtinInfo).originCountryName,
                  goodsType: JSON.parse(v.gtinInfo).goodsType,
                  spec: JSON.parse(v.gtinInfo).spec,
                };
              });
              this.goodsPage.page = res.total;

              //   console.log(this.bindList);
            }
          });
      }
    },
    searchBindInfo() {
      this.bindInfoPage.page = 1;
      this.getBindInfo();
    },
    getGoods() {
      noBaseExtraAxios
        .get(
          "https://1234263519431432.cn-chengdu.fc.aliyuncs.com/2016-08-15/proxy/cll-gtin-api/getGtins/api/gtins",
          this.goodsPage
        )
        .then((response) => {
          const { data, total } = response;
          this.goodsInfo.data = data;
          this.goodsInfo.total = total;
          this.isGoddsSearch = true;
          this.goodsSearchLoading = false;
        });
    },
    searchGoods() {
      this.goodsPage.page = 1;
      this.goodsSearchLoading = true;
      this.getGoods();
    },
    openGoodsDailog() {
      this.goodsVisible = true;
      this.getBindInfo();
    },
    handleBind(row) {
      api
        .get(`/v1/wx/companyUnit/pcUnitList`, {
          gtin: row.code,
        })
        .then((res) => {
          if (res.code === 200) {
            // console.log(res.data);
            if (res.data.length) {
              this.goodsWeightUnitList = res.data.map((v) => {
                return {
                  ...v,
                  label: v.name,
                  value: v.name,
                };
              });
              this.goodsForm.weightUnit = this.goodsWeightUnitList[0].value;
            } else {
              this.goodsForm.weightUnit = "kg";
            }
            this.goodsVisible = false;
            this.goodsBindVisible = true;
            this.goodsForm.code = row.code;
            this.goodsForm.goodsName = row.goodsName;
            this.goodsForm.goodsId = row.goodsId;
            if (row.gtinInfo) {
              this.goodsForm.gtinInfo = row.gtinInfo;
            } else {
              this.goodsForm.gtinInfo = JSON.stringify(row);
            }
            let date = new Date();
            this.goodsForm.batchNumber =
              "" +
              date.getFullYear().toString().substring(2) +
              (date.getMonth() + 1 < 10
                ? "0" + (date.getMonth() + 1)
                : date.getMonth() + 1) +
              (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
              (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
              (date.getMinutes() < 10
                ? "0" + date.getMinutes()
                : date.getMinutes());
            if (row.batchNumber) {
              this.goodsForm.goodsId = row.batchNumber;
            }
          }
        });
    },
    dateChange(e) {
      this.goodsForm.timeStr = formatTime(e, "yyyy-MM-dd");
    },
    bindSubmit() {
      this.$refs.gooodsForm.validate((valid) => {
        if (valid) {
          let data = {
            batchNumber: this.goodsForm.batchNumber,
            dateOfManufacture: this.goodsForm.timeStr,
            qualityGuaranteePeriod: this.goodsForm.qualityGuaranteePeriod,
            imgList: JSON.stringify(this.goodsForm.picUrl),
            productionRecordId: this.productionRecordId,
            gtinInfo: this.goodsForm.gtinInfo,
          };
          if (this.goodsForm.type !== "规格货品") {
            data.goodsWeight =
              this.goodsForm.weightUnit === "kg"
                ? Number(Number(this.goodsForm.goodsWeight).toFixed(4))
                : Number(
                    Number(this.goodsForm.goodsWeight) * (0.001).toFixed(4)
                  );
          } else {
            if (this.goodsWeightUnitList && this.goodsWeightUnitList.length) {
              data.goodsWeight = Number(
                (
                  Number(this.goodsForm.goodsSum) *
                  Number(
                    this.goodsWeightUnitList.filter(
                      (v) => v.name === this.goodsForm.weightUnit
                    )[0].unitWeight
                  )
                ).toFixed(4)
              );
            } else {
              data.goodsSum = this.goodsForm.goodsSum;
              data.goodsUnit =
                this.goodsForm.weightUnit === "kg"
                  ? Number(this.goodsForm.goodsUnit)
                  : this.goodsForm.goodsUnit * 0.001;
              data.goodsWeightUnit = Number(this.goodsForm.goodsWeightUnit);
              data.goodsItem = Number(this.goodsForm.goodsItem);
              data.goodsNumber = Number(this.goodsForm.goodsNumber);
              data.goodsSum =
                Number(this.goodsForm.goodsNumber) *
                  Number(this.goodsForm.goodsUnit) +
                Number(this.goodsForm.goodsItem);
              data.goodsWeight = Number(
                Number(
                  Number(this.goodsForm.goodsWeightUnit) * data.goodsSum
                ).toFixed(4)
              );
            }
          }
          if (this.goodsForm.isEnd === "终结本批") {
            data.finishedTag = Number(this.finishedTag.finishedTag) + 1;
          }
          // console.log(data);
          api
            .post(`/v1/pc/productionLine/bind?companyId=${this.companyId}`, {
              goodsRecord: [data],
            })
            .then((res) => {
              if (res.code === 200) {
                this.goodsBindVisible = false;
                this.bindInfoPage.keyword = "";
                this.getBindInfo();
              }
            });
        } else {
          return false;
        }
      });
    },
    unitChange(e) {
      if (e === "规格货品") {
        if (this.goodsWeightUnitList) {
          this.goodsForm.weightUnit = this.goodsWeightUnitList[0].value;
        }
      } else {
        if (this.goodsWeightUnitList) {
          this.goodsForm.weightUnit = "kg";
        }
      }
    },
    uploadBindFile(params) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          this.goodsForm.picUrl.push({
            url: resp.url,
            name: resp.name,
          });
        }
      });
    },
    handleBindRemove(e) {
      this.$set(
        this.goodsForm,
        "picUrl",
        this.goodsForm.picUrl.filter((v) => v !== e)
      );
      console.log(this.goodsForm.picUrl);

      console.log(e);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  .btn-box {
    justify-content: space-around;
    width: 120px;
  }
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}
</style>


