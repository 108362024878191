<template>
  <el-main
    class="p15 pt0"
    v-loading="!singleTemplate.length"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    element-loading-text="拼命加载中"
  >
    <div
      style="padding: 20px; background-color: #fff; margin-bottom: 20px"
      v-if="singleTemplate.length"
    >
      <el-steps :active="stepStatus.step" align-center>
        <el-step
          icon="el-icon-more"
          :title="`${setpList.before}项`"
          v-if="setpList.before"
        >
        </el-step>
        <el-step
          :title="item.templateName"
          v-for="(item, index) in setpList.list"
          :key="index"
        >
          <template slot="icon">
            <i
              v-if="item.submitInfo.length"
              class="el-icon-success"
              style="color: #67c23a; font-size: 30px"
            ></i>
            <div v-else class="df aic jcc wait-icon-box">
              <i
                class="el-icon-loading"
                style="color: #fff; font-size: 14px"
              ></i>
            </div>
          </template>
        </el-step>
        <el-step
          icon="el-icon-more"
          :title="`${setpList.after}项`"
          v-if="setpList.after"
        >
        </el-step>
      </el-steps>
    </div>
    <el-carousel
      @change="carouselChange"
      ref="carouselRef"
      :loop="false"
      arrow="never"
      :interval="4000"
      type="card"
      height="800px"
      :autoplay="false"
      :initial-index="stepStatus.stepNow"
      indicator-position="none"
    >
      <el-carousel-item v-for="item in singleTemplate" :key="item.id">
        <div class="form-container">
          <div class="form-box">
            <div class="title">
              {{ item.templateName }}
            </div>
            <div
              class="form"
              v-loading="!item.rHaccpDetail"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              element-loading-text="拼命加载中"
            >
              <el-form ref="rHaccpDetailForm" label-width="200px">
                <template v-for="(val, i) in item.rHaccpDetail">
                  <el-form-item :key="val.id + '1'">
                    <div slot="label">
                      <div>
                        <el-radio-group v-model="item.form[i].lag" size="mini">
                          <el-radio-button label="实时上传"></el-radio-button>
                          <el-radio-button label="补录"></el-radio-button>
                        </el-radio-group>
                      </div>
                    </div>
                    <div class="form-info" v-if="item.form[i].lag === '补录'">
                      <el-input
                        v-model="item.form[i].lagReason"
                        placeholder="补录原因"
                      ></el-input>
                    </div>
                  </el-form-item>
                  <el-form-item :key="val.id + '2'">
                    <div slot="label">
                      <div>
                        {{
                          val.haccpRecord
                            ? val.haccpRecord.detailName
                            : val.name
                        }}{{ val.isShow ? "【外】" : "" }}
                      </div>
                    </div>
                    <div v-if="val.type === 1" class="form-info">
                      <el-upload
                        action="#"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="
                          (file, fileList) => handleRHACCPRemove(file, item, i)
                        "
                        :http-request="
                          (params) => {
                            uploadRHACCPFile(params, i, item);
                          }
                        "
                        :file-list="item.form[i].imgs"
                      >
                        <i class="el-icon-plus"></i>
                      </el-upload>
                    </div>
                    <div
                      v-if="val.type === 3 || val.type === 4"
                      class="form-info"
                    >
                      <el-upload
                        class="upload-demo"
                        action="#"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="
                          (file, fileList) => handleVideoRemove(file, item, i)
                        "
                        :http-request="
                          (params) => {
                            uploadVideoFile(params, i, item);
                          }
                        "
                        multiple
                        :file-list="item.form[i].videos"
                      >
                        <el-button size="small" type="primary"
                          >点击上传</el-button
                        >
                      </el-upload>
                    </div>
                    <div v-else-if="val.type === 6" class="form-info">
                      <div
                        v-if="item.form && item.form[i].hasAdd"
                        class="info-box"
                      >
                        <el-form
                          ref="rHaccpDetailFormSensor"
                          label-width="100px"
                        >
                          <el-form-item label="摄像头">
                            <div style="width: 70%">
                              <div
                                v-for="camItem in item.form[i].camInfo"
                                :key="camItem.id"
                              >
                                {{ camItem.name }}
                              </div>
                            </div>
                          </el-form-item>
                          <el-form-item
                            label="时间范围"
                            v-if="
                              item.form[i].startTime && item.form[i].endTime
                            "
                          >
                            <div style="width: 70%">
                              {{ item.form[i].startTime }}至{{
                                item.form[i].endTime
                              }}
                            </div>
                          </el-form-item>
                          <el-form-item
                            label="间隔时间"
                            v-if="item.form[i].intervalTimeStr"
                          >
                            <div style="width: 70%">
                              {{ item.form[i].intervalTimeStr }}
                            </div>
                          </el-form-item>
                          <el-form-item>
                            <el-popover
                              placement="right"
                              width="600"
                              trigger="click"
                              v-model="val.showTimeSet"
                            >
                              <div class="p20">
                                <div class="p20">
                                  <el-form
                                    ref="rHaccpDetailFormSensor"
                                    label-width="100px"
                                  >
                                    <el-form-item label="时间">
                                      <div style="width: 70%" class="df">
                                        <el-date-picker
                                          v-model="item.form[i].time"
                                          type="datetimerange"
                                          range-separator="至"
                                          start-placeholder="开始日期"
                                          format="yyyy-MM-dd HH:mm"
                                          end-placeholder="结束日期"
                                        >
                                        </el-date-picker>
                                      </div>
                                    </el-form-item>
                                    <el-form-item label="间隔时间">
                                      <div style="width: 70%" class="df">
                                        <el-time-picker
                                          v-model="item.form[i].intervalTime"
                                          :picker-options="{
                                            selectableRange:
                                              '00:01:00 - 23:59:00',
                                          }"
                                          format="HH:mm"
                                          placeholder="间隔时间"
                                        >
                                        </el-time-picker>
                                      </div>
                                    </el-form-item>
                                  </el-form>
                                </div>
                                <div style="text-align: right; margin: 0">
                                  <el-button
                                    size="mini"
                                    type="text"
                                    @click="clearFormData(item, i, val)"
                                    >取消</el-button
                                  >
                                  <el-button
                                    type="primary"
                                    size="mini"
                                    @click="createFormData(item, val, i)"
                                    >确定</el-button
                                  >
                                </div>
                              </div>
                              <el-button slot="reference" type="primary">{{
                                item.form[i].startTime ||
                                item.form[i].endTime ||
                                item.form[i].intervalTime
                                  ? "修改时间"
                                  : "设置时间"
                              }}</el-button>
                            </el-popover>
                          </el-form-item>
                        </el-form>
                      </div>
                      <el-popover
                        placement="right"
                        width="300"
                        v-model="val.showPopover"
                        trigger="click"
                        @show="getCamList"
                      >
                        <div class="p20">
                          <div class="p20">
                            <el-table
                              :data="camList"
                              ref="camListTable"
                              style="width: 100%"
                              @selection-change="(e) => checkChange(e, 0)"
                              :show-header="false"
                            >
                              <el-table-column type="selection" width="80">
                              </el-table-column>
                              <el-table-column prop="name" width="120">
                              </el-table-column>
                            </el-table>
                          </div>

                          <div style="text-align: right; margin: 0">
                            <el-button
                              size="mini"
                              type="text"
                              @click="val.showPopover = false"
                              >取消</el-button
                            >
                            <el-button
                              type="primary"
                              size="mini"
                              @click="checkEvent(val, item, i, 0)"
                              >确定</el-button
                            >
                          </div>
                        </div>
                        <el-button slot="reference" type="primary">{{
                          item.form[i].hasAdd ? "重新选择" : "选择摄像头"
                        }}</el-button>
                      </el-popover>
                    </div>

                    <div v-else-if="val.type === 5" class="form-info">
                      <div
                        v-if="
                          item.form && item.form[i].hasAdd && item.form[i].imgs
                        "
                        class="info-box"
                      >
                        <el-form
                          ref="rHaccpDetailFormSensor"
                          label-width="100px"
                        >
                          <el-form-item label="摄像头">
                            <div style="width: 70%">
                              <div
                                v-for="camItem in item.form[i].imgs"
                                :key="camItem.id"
                              >
                                <div>
                                  {{ camItem.name }}
                                </div>
                                <div>
                                  {{ camItem.time }}
                                </div>
                                <div
                                  v-if="camItem.imgUrl"
                                  @click="pictureCardPreview(camItem.imgUrl)"
                                >
                                  <img
                                    style="
                                      width: 80px;
                                      height: 80px;
                                      object-fit: cover;
                                    "
                                    :src="camItem.imgUrl"
                                    alt="加载失败"
                                  />
                                </div>
                              </div>
                            </div>
                          </el-form-item>
                        </el-form>
                      </div>
                      <el-popover
                        placement="right"
                        width="300"
                        v-model="val.showPopover"
                        trigger="click"
                        @show="getCamList"
                      >
                        <div class="p20">
                          <div class="p20">
                            <el-table
                              :data="camList"
                              ref="camListTable"
                              style="width: 100%"
                              @selection-change="(e) => checkChange(e, 0)"
                              :show-header="false"
                            >
                              <el-table-column type="selection" width="80">
                              </el-table-column>
                              <el-table-column prop="name" width="120">
                              </el-table-column>
                            </el-table>
                          </div>

                          <div style="text-align: right; margin: 0">
                            <el-button
                              size="mini"
                              type="text"
                              @click="val.showPopover = false"
                              >取消</el-button
                            >
                            <el-button
                              type="primary"
                              size="mini"
                              @click="checkEvent(val, item, i, 0, 1)"
                              >抓拍</el-button
                            >
                          </div>
                        </div>
                        <el-button slot="reference" type="primary">{{
                          item.form[i].hasAdd ? "重新选择" : "选择摄像头"
                        }}</el-button>
                      </el-popover>
                    </div>
                    <div v-else-if="val.type === 7" class="form-info">
                      <div
                        v-if="item.form && item.form[i].hasAdd"
                        class="info-box"
                      >
                        <el-form
                          ref="rHaccpDetailFormSensor"
                          label-width="100px"
                        >
                          <el-form-item label="传感器">
                            <div style="width: 70%">
                              <div
                                v-for="sensorItem in item.form[i].sensorInfo"
                                :key="sensorItem.id"
                              >
                                {{ sensorItem.deviceName }}
                              </div>
                            </div>
                          </el-form-item>
                        </el-form>
                      </div>
                      <el-popover
                        placement="right"
                        width="300"
                        v-model="val.showPopover"
                        trigger="click"
                        @show="getSensorList"
                      >
                        <div class="p20">
                          <div class="p20">
                            <el-table
                              :data="sensorList"
                              ref="camListTable"
                              style="width: 100%"
                              @selection-change="(e) => checkChange(e, 1)"
                              :show-header="false"
                            >
                              <el-table-column type="selection" width="80">
                              </el-table-column>
                              <el-table-column prop="deviceName" width="120">
                              </el-table-column>
                            </el-table>
                          </div>

                          <div style="text-align: right; margin: 0">
                            <el-button
                              size="mini"
                              type="text"
                              @click="val.showPopover = false"
                              >取消</el-button
                            >
                            <el-button
                              type="primary"
                              size="mini"
                              @click="checkEvent(val, item, i, 1)"
                              >确定</el-button
                            >
                          </div>
                        </div>
                        <el-button slot="reference" type="primary">{{
                          item.form[i].hasAdd ? "重新选择" : "选择传感器"
                        }}</el-button>
                      </el-popover>
                    </div>
                    <div v-else-if="val.type === 8" class="form-info">
                      <div
                        v-if="item.form && item.form[i].hasAdd"
                        class="info-box"
                      >
                        <el-form
                          ref="rHaccpDetailFormSensor"
                          label-width="100px"
                        >
                          <el-form-item label="传感器">
                            <div style="width: 70%">
                              <div
                                v-for="sensorItem in item.form[i].sensorInfo"
                                :key="sensorItem.id"
                              >
                                {{ sensorItem.deviceName }}
                              </div>
                            </div>
                          </el-form-item>
                          <el-form-item
                            label="时间"
                            v-if="
                              item.form[i].startTime && item.form[i].endTime
                            "
                          >
                            <div style="width: 70%" class="df">
                              {{ item.form[i].startTime }}至{{
                                item.form[i].endTime
                              }}
                            </div>
                          </el-form-item>
                          <el-form-item
                            label="间隔时间"
                            v-if="item.form[i].intervalTimeStr"
                          >
                            <div style="width: 70%" class="df">
                              {{ item.form[i].intervalTimeStr }}
                            </div>
                          </el-form-item>
                          <el-form-item>
                            <el-popover
                              placement="right"
                              width="600"
                              trigger="click"
                              v-model="val.showTimeSet"
                            >
                              <div class="p20">
                                <div class="p20">
                                  <el-form
                                    ref="rHaccpDetailFormSensor"
                                    label-width="100px"
                                  >
                                    <el-form-item label="时间">
                                      <div style="width: 70%" class="df">
                                        <el-date-picker
                                          v-model="item.form[i].time"
                                          type="datetimerange"
                                          range-separator="至"
                                          start-placeholder="开始日期"
                                          end-placeholder="结束日期"
                                          format="yyyy-MM-dd HH:mm"
                                        >
                                        </el-date-picker>
                                      </div>
                                    </el-form-item>
                                    <el-form-item label="间隔时间">
                                      <div style="width: 70%" class="df">
                                        <el-time-picker
                                          format="HH:mm"
                                          v-model="item.form[i].intervalTime"
                                          :picker-options="{
                                            selectableRange:
                                              '00:01:00 - 23:59:00',
                                          }"
                                          placeholder="间隔时间"
                                        >
                                        </el-time-picker>
                                      </div>
                                    </el-form-item>
                                  </el-form>
                                </div>
                                <div style="text-align: right; margin: 0">
                                  <el-button
                                    size="mini"
                                    type="text"
                                    @click="clearFormData(item, i, val)"
                                    >取消</el-button
                                  >
                                  <el-button
                                    type="primary"
                                    size="mini"
                                    @click="createFormData(item, val, i)"
                                    >确定</el-button
                                  >
                                </div>
                              </div>
                              <el-button slot="reference" type="primary">{{
                                item.form[i].startTime ||
                                item.form[i].endTime ||
                                item.form[i].intervalTime
                                  ? "修改时间"
                                  : "设置时间"
                              }}</el-button>
                            </el-popover>
                          </el-form-item>
                        </el-form>
                      </div>
                      <el-popover
                        placement="right"
                        width="300"
                        v-model="val.showPopover"
                        trigger="click"
                        @show="getSensorList"
                      >
                        <div class="p20">
                          <div class="p20">
                            <el-table
                              :data="sensorList"
                              ref="camListTable"
                              style="width: 100%"
                              @selection-change="(e) => checkChange(e, 1)"
                              :show-header="false"
                            >
                              <el-table-column type="selection" width="80">
                              </el-table-column>
                              <el-table-column prop="deviceName" width="120">
                              </el-table-column>
                            </el-table>
                          </div>

                          <div style="text-align: right; margin: 0">
                            <el-button
                              size="mini"
                              type="text"
                              @click="val.showPopover = false"
                              >取消</el-button
                            >
                            <el-button
                              type="primary"
                              size="mini"
                              @click="checkEvent(val, item, i, 1)"
                              >确定</el-button
                            >
                          </div>
                        </div>
                        <el-button slot="reference" type="primary">{{
                          item.form[i].hasAdd ? "重新选择" : "选择传感器"
                        }}</el-button>
                      </el-popover>
                    </div>
                    <div v-else-if="val.type === 9" class="form-info">
                      <div
                        v-if="
                          item.form &&
                          item.form[i].hasAdd &&
                          item.form[i].quickList
                        "
                        style="margin-bottom: 14px"
                      >
                        <div
                          class="info-box"
                          v-for="quickItem in item.form[i].quickList"
                          :key="quickItem.id"
                          style="
                            width: 300px;
                            padding: 10px 20px;
                            margin-bottom: 6px;
                          "
                        >
                          <div
                            class="df"
                            style="justify-content: space-between"
                          >
                            <span>快检名称:</span>
                            <span>{{ quickItem.name }}</span>
                          </div>
                          <div
                            class="df"
                            style="justify-content: space-between"
                          >
                            <span>快检时间:</span>
                            <span>{{ quickItem.time }}</span>
                          </div>
                        </div>
                      </div>
                      <el-popover
                        placement="right"
                        width="600"
                        v-model="val.showPopover"
                        trigger="click"
                        @show="getQuickList"
                      >
                        <div class="p20">
                          <div class="p20">
                            <el-table
                              :data="quickList"
                              ref="quickListTable"
                              style="width: 100%"
                              @selection-change="(e) => checkChange(e, 2)"
                            >
                              <el-table-column type="selection" width="80">
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="快检名称"
                                width="120"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="time"
                                label="快检时间"
                                width="180"
                              >
                              </el-table-column>
                            </el-table>
                          </div>

                          <div style="text-align: right; margin: 0">
                            <el-button
                              size="mini"
                              type="text"
                              @click="val.showPopover = false"
                              >取消</el-button
                            >
                            <el-button
                              type="primary"
                              size="mini"
                              @click="checkEvent(val, item, i, 2)"
                              >确定</el-button
                            >
                          </div>
                        </div>
                        <el-button slot="reference" type="primary">{{
                          item.form[i].hasAdd ? "修改" : "设置"
                        }}</el-button>
                      </el-popover>
                    </div>
                    <div class="form-info" v-else-if="val.type === 2">
                      <el-input v-model="item.form[i].remarks"></el-input>
                    </div>
                  </el-form-item>
                </template>
                <el-form-item>
                  <div class="form-info">
                    <el-button
                      v-if="!item.submitInfo.length || item.submitInfo.type"
                      type="primary"
                      @click="SubmitRHaccpDetail(item)"
                      >提交</el-button
                    >
                    <el-button v-else type="primary">修改</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="df aic jcc" style="width: 100%">
      <div
        v-for="(item, index) in singleTemplate"
        :key="`indicator${item.id}`"
        :class="
          item.submitInfo.length ? 'indicator-box update' : 'indicator-box'
        "
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.templateName"
          placement="top-start"
        >
          <div
            @click="carouselChangeManual(index)"
            :class="
              stepStatus.stepNow === index ? 'indicator-now' : 'indicator'
            "
          ></div>
        </el-tooltip>
      </div>
    </div>
  </el-main>
</template>
<script>
import api from "@/util/extra-api";
import { formatTime } from "@/util/util";
import { local } from "@/util/util";
import axios from "@/util/api";

export default {
  data() {
    return {
      finishedTag: null,
      singleTemplate: [],
      stepStatus: {
        step: 17,
        stepNow: 0,
      },
      camList: [],
      sensorList: [],
      quickList: [],
    };
  },
  computed: {
    setpList() {
      let data = {
        step: null,
        list: [],
        showNow: null,
        before: 0,
        after: 0,
      };
      if (this.singleTemplate.length > 5) {
        if (this.stepStatus.stepNow < 3) {
          data.list = this.singleTemplate.filter((v, i) => i < 5);
          if (this.stepStatus.step < 5) {
            data.step = this.stepStatus.step;
          } else {
            data.step = 5;
          }
          data.before = 0;
          data.after = this.singleTemplate.length - 5;
        } else if (this.singleTemplate.length - this.stepStatus.stepNow < 3) {
          data.list = this.singleTemplate.filter(
            (v, i) => this.singleTemplate.length - i < 6
          );
          if (this.singleTemplate.length - this.stepStatus.step < 6) {
            data.step = 5 - this.singleTemplate.length + this.stepStatus.step;
          } else {
            data.step = 0;
          }
          data.before = this.singleTemplate.length - 5;
          data.after = 0;
        } else {
          data.list = this.singleTemplate.filter(
            (v, i) =>
              this.stepStatus.stepNow - i < 3 &&
              this.stepStatus.stepNow - i > -3
          );
          if (this.stepStatus.stepNow - this.stepStatus.step > 2) {
            data.step = 0;
          } else if (this.stepStatus.step - this.stepStatus.stepNow > 2) {
            data.step = 5;
          } else {
            data.step = this.stepStatus.step - this.stepStatus.stepNow + 2;
          }
          data.before = this.stepStatus.stepNow - 2;
          data.after = this.singleTemplate.length - this.stepStatus.stepNow - 3;
        }
        data.showNow = this.singleTemplate.indexOf(data.list[3]);
        // console.log(data, this.stepStatus.stepNow);
        if (!this.mandatoryFlag) {
          data.step = 0;
        }
        // console.log(data);
        return data;
      } else {
        return {
          before: 0,
          after: 0,
          step: this.mandatoryFlag ? this.stepStatus.step : 0,
          list: this.singleTemplate,
          showNow: 3,
        };
      }
    },
  },
  props: {
    companyId: Number,
    productionRecordId: Number,
    mandatory: Boolean,
    productionLineId: Number,
  },
  mounted() {
    this.getSingleTemplate();
  },
  methods: {
    pictureCardPreview(url) {
      window.open(url);
    },
    handlePictureCardPreview(file) {
      //查看图片
      // console.log(file, 1);
      window.open(file.url);
    },
    // stepChange(type) {
    //   if (type) {
    //     if (this.stepStatus.stepNow !== this.testStepList.length) {
    //       this.stepStatus.stepNow++;
    //     }
    //   } else {
    //     if (this.stepStatus.stepNow !== 0) {
    //       this.stepStatus.stepNow--;
    //     }
    //   }
    // },
    getSingleTemplate() {
      api
        .get(`/v1/wx/productionLine/finishedTag`, {
          type: 0,
          productionRecordId: this.productionRecordId,
        })
        .then((res) => {
          if (res.code === 200) {
            // console.log(res.data);
            this.finishedTag = res.data;
            api
              .get(`/v1/pc/productionLine/rHaccp?companyId=${this.companyId}`, {
                productionLineId: this.productionLineId,
                productionRecordId: this.productionRecordId,
                finishedTag: Number(res.data.finishedTag) + 1,
              })
              .then((resp) => {
                if (resp.code === 200) {
                  this.singleTemplate = resp.data.map((v) => {
                    return {
                      ...v,
                      form: [],
                    };
                  });
                  this.getRHaccpDetail(0);
                }
              });
          }
        });
    },
    //点击指示器改变选中工序
    carouselChange(e) {
      this.$set(this.stepStatus, "stepNow", e);
      this.getRHaccpDetail(e);
    },
    carouselChangeManual(index) {
      this.$set(this.stepStatus, "stepNow", index);
      this.$refs.carouselRef.setActiveItem(index);
    },
    getRHaccpDetail(step) {
      // console.log(1, step);
      if (this.singleTemplate.length) {
        if (!this.singleTemplate[step].rHaccpDetail) {
          // console.log("0");
          this.getSingleRHaccpDetail(
            this.singleTemplate[step].id,
            this.singleTemplate[step]
          );
        }
        if (step - 1 >= 0) {
          if (!this.singleTemplate[step - 1].rHaccpDetail) {
            // console.log("-1");
            this.getSingleRHaccpDetail(
              this.singleTemplate[step - 1].id,
              this.singleTemplate[step - 1]
            );
          }
        }
        if (step + 1 <= this.singleTemplate.length - 1) {
          if (!this.singleTemplate[step + 1].rHaccpDetail) {
            // console.log("+1");
            this.getSingleRHaccpDetail(
              this.singleTemplate[step + 1].id,
              this.singleTemplate[step + 1]
            );
          }
        }
      }
    },
    getSingleRHaccpDetail(id, data) {
      api
        .get(`/v1/pc/productionLine/rHaccpDetail?companyId=${this.companyId}`, {
          detailId: id,
          recordId: this.productionRecordId,
          finishedTag: Number(this.finishedTag.finishedTag) + 1,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$set(
              data,
              "rHaccpDetail",
              res.data.map((v) => {
                let val = v;
                if ([5, 6, 7, 8].includes(v.type)) {
                  val.showPopover = false;
                }
                if ([6, 8].includes(v.type)) {
                  val.showTimeSet = false;
                }
                return val;
              })
            );
            let formData = [];
            res.data.forEach((v) => {
              formData.push(this.formDataItem(v));
            });
            this.$set(data, "form", formData);
            // console.log(data, 111);
          }
        });
    },
    getSensorList() {
      if (!this.sensorList.length) {
        api
          .get(`/v1/pc/productionLine/existDevice?companyId=${this.companyId}`)
          .then((res) => {
            if (res.code === 200) {
              this.sensorList = res.data.map((v) => {
                return {
                  ...v,
                  value: v.deviceName,
                  label: v.deviceName,
                  checked: false,
                };
              });
            }
          });
      }
    },
    getQuickList() {
      api
        .get(`/v1/wx/farmDetection/pcList?companyId=${this.companyId}`)
        .then((res) => {
          if (res.code === 200) {
            this.quickList = res.data.map((v) => {
              v.time = v.time.replace("T", " ");
              v.isChecked = false;
              return v;
            });
          }
        });
    },
    getCamList() {
      if (!this.camList.length) {
        api
          .get(
            `/v1/pc/productionLine/getCompanyCam?companyId=${this.companyId}`
          )
          .then((res) => {
            if (res.code === 200) {
              this.camList = res.data.map((v) => {
                return {
                  ...v,
                  label: v.name,
                  value: v.name,
                  checked: false,
                };
              });
            }
          });
      }
    },
    //type 0 摄像头 1传感器
    formDataChange(e, data, index, type) {
      if (!type) {
        this.$set(
          data.form[index],
          "camInfo",
          this.camList.filter((v) => v.value === e)[0]
        );
      } else {
        this.$set(
          data.form[index],
          "sensorInfo",
          this.camList.filter((v) => v.value === e)[0]
        );
      }
    },
    createFormData(data, val, i) {
      // console.log(data.form[i]);
      this.$set(
        data.form[i],
        "startTime",
        formatTime(data.form[i].time[0], "yyyy-MM-dd HH:mm")
      );
      this.$set(
        data.form[i],
        "endTime",
        formatTime(data.form[i].time[1], "yyyy-MM-dd HH:mm")
      );
      this.$set(
        data.form[i],
        "intervalTimeStr",
        formatTime(data.form[i].intervalTime, "HH:mm")
      );
      val.showTimeSet = false;
      this.$set(data.form[i], "hasAdd", true);
    },
    clearFormData(item, i, val) {
      this.$set(item.form, i, this.formDataItem(val));
      if (val.showTimeSet) {
        val.showTimeSet = false;
      } else {
        val.showPopover = false;
      }
    },
    formDataItem(val) {
      let data = null;
      switch (val.type) {
        case 1:
          data = { imgs: [], hasAdd: false };
          if (val.haccpRecord && val.haccpRecord.picUrl) {
            data.imgs = JSON.parse(val.haccpRecord.picUrl);
            data.imgs.forEach((v, i) => {
              v.index = i;
            });
            data.hasAdd = true;
          }
          break;
        case 2:
          data = { remarks: "", hasAdd: false };
          if (val.haccpRecord && val.haccpRecord.picUrl) {
            data.remarks = val.haccpRecord.remarks;
            data.hasAdd = true;
          }
          break;
        case 3:
          data = { videos: [], hasAdd: false };
          if (val.haccpRecord && val.haccpRecord.otherContents) {
            data.videos = JSON.parse(val.haccpRecord.otherContents).map(
              (v, i) => {
                return {
                  url: v,
                  name: v.slice(33),
                };
              }
            );
            data.hasAdd = true;
          }
          break;
        case 4:
          data = { videos: [], hasAdd: false };
          if (val.haccpRecord && val.haccpRecord.otherContents) {
            data.videos = JSON.parse(val.haccpRecord.otherContents).map(
              (v, i) => {
                return {
                  url: v,
                  name: v.slice(33),
                };
              }
            );
            data.hasAdd = true;
          }
          break;
        case 5:
          data = { camInfo: null, imgs: null, hasAdd: false };
          if (val.haccpRecord && val.haccpRecord.otherContents) {
            data.imgs = JSON.parse(val.haccpRecord.otherContents);
            data.hasAdd = true;
          }
          break;
        case 6:
          data = {
            camInfo: null,
            time: null,
            intervalTime: "",
            hasAdd: false,
          };
          if (val.haccpRecord && val.haccpRecord.taskInfo) {
            data.camInfo = val.haccpRecord.taskInfo.name.map((v, i) => {
              return {
                name: v,
                id: val.haccpRecord.taskInfo.id[i],
              };
            });
            data.startTime = val.haccpRecord.taskInfo.startTime.replace(
              "T",
              " "
            );
            data.endTime = val.haccpRecord.taskInfo.endTime.replace("T", " ");
            data.intervalTimeStr = val.haccpRecord.taskInfo.intervalTime;
            data.hasAdd = true;
          }
          break;
        case 7:
          data = {
            sensorInfo: null,
            hasAdd: false,
          };
          if (val.haccpRecord && val.haccpRecord.otherContents) {
            data.sensorInfo = JSON.parse(val.haccpRecord.otherContents);
            data.hasAdd = true;
          }
          break;
        case 8:
          data = {
            sensorInfo: null,
            time: null,
            intervalTime: "",
            hasAdd: false,
          };
          if (val.haccpRecord && val.haccpRecord.taskInfo) {
            data.sensorInfo = val.haccpRecord.taskInfo.name.map((v, i) => {
              return {
                deviceName: v,
                id: val.haccpRecord.taskInfo.id[i],
              };
            });
            data.startTime = val.haccpRecord.taskInfo.startTime.replace(
              "T",
              " "
            );
            data.endTime = val.haccpRecord.taskInfo.endTime.replace("T", " ");
            data.intervalTimeStr = val.haccpRecord.taskInfo.intervalTime;
            data.hasAdd = true;
          }
          break;
        case 9:
          data = { quickList: [], hasAdd: false };
          break;
      }
      data.type = val.type;
      data.id = val.id;
      if (val.haccpRecord && val.haccpRecord.lagReason) {
        data.lagReason = val.haccpRecord.lagReason;
      } else {
        data.lagReason = "";
      }
      if (val.haccpRecord && val.haccpRecord.lag1) {
        data.lag = val.haccpRecord.lag1 ? "补录" : "实时上传";
      } else {
        data.lag = "实时上传";
      }
      return data;
    },
    handleRHACCPRemove(file, data, i) {
      this.$set(
        data.form[i],
        "imgs",
        data.form[i].imgs.filter((v) => v.name !== file.name)
      );
      // console.log(data.form[i].imgs);
    },
    uploadRHACCPFile(params, i, data) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return false;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          let imgs = data.form[i].imgs;
          imgs.push({
            name: resp.name,
            url: resp.url,
          });
          this.$set(data.form[i], "imgs", imgs);
        }
      });
    },
    handleVideoRemove(file, data, i) {
      this.$set(
        data.form[i],
        "videos",
        data.form[i].videos.filter((v) => v.name !== file.name)
      );
      // console.log(data.form[i].imgs);
    },
    uploadVideoFile(params, i, data) {
      const file = params.file,
        fileType = file.type,
        isVidoe = fileType.includes("video");
      // console.log(file, isVidoe);
      if (!isVidoe) {
        this.$message.error("只能上传视频格式!");
        return false;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("视频上传失败，请重新上传");
        } else {
          // console.log(resp.data);
          let videos = null;
          if (data.form[i].videos) {
            videos = data.form[i].videos;
          } else {
            videos = [];
          }
          videos.push({
            name: resp.name,
            url: resp.url,
          });
          this.$set(data.form[i], "videos", videos);
          // console.log(data.form[i].videos);
        }
      });
    },
    SubmitRHaccpDetail(data) {
      let haccpRecordDetailList = [];
      data.form.forEach((v) => {
        let val = this.formatSingleRHaccpDetail(data, v);
        haccpRecordDetailList.push(val);
      });
      // console.log(haccpRecordDetailList);

      api
        .post(
          `/v1/pc/productionLine/rAddHaccpDetail?companyId=${this.companyId}`,
          {
            haccpRecordDetailList,
          }
        )
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
          }
        });
    },
    formatSingleRHaccpDetail(data, val) {
      let returnVal = null;
      // console.log(val, 2222222);
      switch (val.type) {
        case 1:
          returnVal = {
            coordinate: "0,0",
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
            haccpDetailId: val.id,
            lag1: val.lag === "补录",
            lagReason: val.lagReason,
            picUrl: JSON.stringify(val.imgs),
            productionRecordId: this.productionRecordId,
          };
          break;
        case 2:
          returnVal = {
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
            haccpDetailId: val.id,
            lag1: val.lag === "补录",
            lagReason: val.lagReason,
            productionRecordId: this.productionRecordId,
            remarks: val.remarks,
            picUrl: JSON.stringify([]),
          };
          break;
        case 3:
          returnVal = {
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
            haccpDetailId: val.id,
            lag1: val.lag === "补录",
            lagReason: val.lagReason,
            productionRecordId: this.productionRecordId,
            picUrl: JSON.stringify([]),
            otherContents: JSON.stringify(val.videos.map((v) => v.url)),
          };
          break;
        case 4:
          returnVal = {
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
            haccpDetailId: val.id,
            lag1: val.lag === "补录",
            lagReason: val.lagReason,
            productionRecordId: this.productionRecordId,
            picUrl: JSON.stringify([]),
            otherContents: JSON.stringify(val.videos.map((v) => v.url)),
          };
          break;
        case 5:
          returnVal = {
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
            haccpDetailId: val.id,
            lag1: val.lag === "补录",
            lagReason: val.lagReason,
            productionRecordId: this.productionRecordId,
            picUrl: JSON.stringify([]),
            otherContents: JSON.stringify(val.imgs),
          };
          break;
        case 6:
          returnVal = {
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
            haccpDetailId: val.id,
            lag1: val.lag === "补录",
            lagReason: val.lagReason,
            productionRecordId: this.productionRecordId,
            iotTimerTask: {
              id: val.camInfo.map((v) => v.id),
              startTime: val.startTime.replace(" ", "T"),
              endTime: val.startTime.replace(" ", "T"),
              intervalTime: val.intervalTimeStr,
              type: "task_haccp_cam",
              name: val.camInfo.map((v) => v.name),
            },
            picUrl: JSON.stringify([]),
            otherContents: JSON.stringify([]),
          };
          break;
        case 7:
          returnVal = {
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
            haccpDetailId: val.id,
            lag1: val.lag === "补录",
            lagReason: val.lagReason,
            productionRecordId: this.productionRecordId,
            picUrl: JSON.stringify([]),
            otherContents: JSON.stringify(
              val.sensorInfo.map((v) => {
                return {
                  id: v.id,
                  deviceId: v.deviceId,
                  deviceName: v.deviceName,
                };
              })
            ),
          };
          break;
        case 8:
          returnVal = {
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
            haccpDetailId: val.id,
            lag1: val.lag === "补录",
            lagReason: val.lagReason,
            productionRecordId: this.productionRecordId,
            iotTimerTask: {
              id: val.sensorInfo.map((v) => v.id),
              startTime: val.startTime.replace(" ", "T"),
              endTime: val.endTime.replace(" ", "T"),
              intervalTime: val.intervalTimeStr,
              type: "task_haccp_sensor",
              name: val.sensorInfo.map((v) => v.deviceName),
            },
            otherContents: JSON.stringify([]),

            picUrl: JSON.stringify([]),
          };
          break;
        case 9:
          returnVal = {
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
            haccpDetailId: val.id,
            lag1: val.lag === "补录",
            lagReason: val.lagReason,
            productionRecordId: this.productionRecordId,
            picUrl: JSON.stringify([]),
            otherContents: JSON.stringify(val.quickList.map((v) => v.id)),
          };
          break;
      }
      if (data.type === 1) {
        if (!data.haccpDetailList) {
          returnVal.num = 1;
        } else {
          returnVal.num = data.haccpDetailList.length + 1;
        }
      }
      returnVal.detailType = val.type;
      return returnVal;
    },
    //type 0 摄像头 1 传感器 2 快检
    checkChange(e, type) {
      // console.log(e);
      if (type === 0) {
        this.camList.forEach((v) => (v.checked = false));
        e.forEach((v) => (v.checked = true));
      }
      if (type === 1) {
        this.sensorList.forEach((v) => (v.checked = false));
        e.forEach((v) => (v.checked = true));
      }
      if (type === 2) {
        this.quickList.forEach((v) => (v.checked = false));
        e.forEach((v) => (v.checked = true));
      }
    },
    checkEvent(val, data, i, type, status) {
      if (type === 0) {
        if (status) {
          let date = formatTime(new Date(), "yyyy-MM-dd HH:mm");
          let imgs = [];
          this.camList
            .filter((v) => v.checked)
            .forEach((camItem) => {
              api
                .get(`/v1/wx/companyDetail/camImg`, {
                  camId: camItem.camId,
                  siteId: camItem.siteId,
                  time: date,
                })
                .then((res) => {
                  if (res.code === 200) {
                    imgs.push({
                      imgUrl: res.data.url,
                      time: date,
                      name: camItem.name,
                    });
                    if (
                      imgs.length ===
                      this.camList.filter((v) => v.checked).length
                    ) {
                      this.$set(data.form[i], "imgs", imgs);
                    }
                  }
                });
            });
        } else {
          this.$set(
            data.form[i],
            "camInfo",
            this.camList.filter((v) => v.checked)
          );
        }
      } else if (type === 1) {
        this.$set(
          data.form[i],
          "sensorInfo",
          this.sensorList.filter((v) => v.checked)
        );
      } else {
        this.$set(
          data.form[i],
          "quickList",
          this.quickList.filter((v) => v.checked)
        );
      }
      val.showPopover = false;
      this.$set(data.form[i], "hasAdd", true);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  .btn-box {
    justify-content: space-around;
    width: 120px;
  }
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}

.product {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  border-radius: 40px;
  overflow: hidden;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
  border-radius: 40px;
  overflow: hidden;
}

.wait-icon-box {
  background-color: #aaa;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.indicator-box {
  padding: 12px 4px;
  .indicator {
    width: 30px;
    height: 10px;
    border-radius: 5px;
    background-color: #99a9bf;
    cursor: pointer;
  }
  .indicator-now {
    width: 36px;
    height: 14px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #475669;
  }
}

.update {
  .indicator {
    background-color: #19be6b !important;
  }
  .indicator-now {
    background-color: #18b566 !important;
  }
}

.form-container {
  padding: 40px;
  height: 100%;
  .form-box {
    height: 100%;
    border-radius: 34px;
    background-color: #fff;
    overflow: hidden;
    .title {
      padding: 20px;
      font-size: 24px;
      font-weight: bold;
      color: #99a9bf;
    }
    .form {
      padding-top: 0;
      padding: 20px;
      height: 90%;
      overflow-y: scroll;
      overflow-x: hidden;
      .form-info {
        text-align: left;
        width: 80%;
        padding-left: 40px;
        .info-box {
          border: 2px solid #475669;
          border-radius: 20px;
          margin-bottom: 20px;
          width: 400px;
        }
      }
    }
    .form::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 8px;
    }
    .form::-webkit-scrollbar-thumb {
      border-radius: 5px;
      // background: rgba(255, 255, 255, 0.8);
      background: #bbb;
      transition: color 0.2s ease;
      cursor: pointer;
    }
  }
}
</style>


