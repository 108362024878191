<template>
  <el-main class="p15 pt0">
    <div class="pt20" style="text-align: left">
      <el-form :inline="true" :model="templatePage" class="demo-form-inline">
        <el-form-item>
          <el-input
            placeholder="模板名"
            v-model="templatePage.keyword"
            clearable
            @clear="searchTemplate"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchTemplate" size="medium"
            >&emsp;查询&emsp;</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="createPopover"
          >
            <div class="p15">
              <el-form
                :model="pLineEditForm"
                ref="pLineEditForm"
                label-width="100px"
                class="demo-dForm"
                :rules="cListRule"
              >
                <el-form-item :label="'模板名'" prop="name" required>
                  <div style="width: 80%">
                    <el-input v-model="pLineEditForm.name"></el-input>
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createPopover = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="createTemplateItem"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button slot="reference" type="success" size="medium"
              >创建模板</el-button
            >
          </el-popover>
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-row class="box search mt20">
      <el-form :inline="true" :model="templatePage" class="demo-form-inline">
        <el-col :span="4" class="mr10">
          <el-form-item size="medium">
            <el-input
              placeholder="模板名"
              v-model="templatePage.keyword"
              clearable
              class="input-with-select"
              @clear="searchTemplate"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <div class="df">
          <el-button
            style="height: 36px"
            type="primary"
            @click="searchTemplate"
            class="general_bgc general_border cfff mr10"
            >&emsp;查询&emsp;</el-button
          >
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="createPopover"
          >
            <div class="p15">
              <el-form
                :model="pLineEditForm"
                ref="pLineEditForm"
                label-width="100px"
                class="demo-dForm"
                :rules="cListRule"
              >
                <el-form-item :label="'模板名'" prop="name" required>
                  <div style="width: 80%">
                    <el-input v-model="pLineEditForm.name"></el-input>
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click="createPopover = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  @click="createTemplateItem"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button
              slot="reference"
              type="success"
              class="mr10"
              style="height: 36px"
              >创建模板</el-button
            >
          </el-popover>
        </div>
      </el-form>
    </el-row> -->
    <el-table
      :data="templateData"
      style="width: 100%"
      border
      empty-text="暂无模板"
    >
      <el-table-column label="序号" type="index" width="50" align="center">
      </el-table-column>
      <el-table-column
        prop="authorityManagement"
        label="是否启用"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <div @click.capture="tListId = scope.row.id">
            <el-switch v-model="scope.row.status" @change="templateChange">
            </el-switch>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="模板名" width="180" align="center">
      </el-table-column>
      <el-table-column
        prop="createdTime"
        label="创建时间"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.createdTime.replace("T", " ") }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <div class="btn-container jcc df">
            <div
              class="btn-box df"
              :style="
                scope.row.authorityManagement ? 'width:280px' : 'width:120px'
              "
            >
              <el-button
                v-if="scope.row.authorityManagement"
                type="text"
                class="disib"
                size="mini"
                @click="openManagement(scope.row)"
                >权限管理</el-button
              >
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="openTemplate(scope.row)"
                >模板管理</el-button
              >
              <el-popover
                v-if="!scope.row.isEdit"
                placement="bottom"
                width="200"
                trigger="click"
                v-model="scope.row.isDel"
              >
                <div class="p15">
                  <div class="tc p10">是否删除该产品线?</div>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="text"
                      @click="scope.row.isDel = false"
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="delTemplateItem(scope.row)"
                      >确定</el-button
                    >
                  </div>
                </div>
                <el-button
                  slot="reference"
                  type="text"
                  class="disib red"
                  size="mini"
                  >删除</el-button
                >
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="i-page disib mt20">
      <el-pagination
        background
        :page-size="10"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="templatePage.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="模板管理" :visible.sync="dailogVisible">
      <div class="pt20" style="text-align: left">
        <el-form :inline="true" :model="feedingPage" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="原材料"
              v-model="feedingPage.keyword"
              clearable
              size="medium"
              @clear="feedingSearch"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="feedingSearch" size="medium"
              >&emsp;查询&emsp;</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-popover
              placement="bottom"
              width="500"
              trigger="click"
              v-model="diglogPopover"
            >
              <div class="p15" v-if="diglogPopover">
                <el-form
                  :model="createFeedingForm"
                  ref="createFeedingForm"
                  label-width="130px"
                  class="demo-dForm"
                  :rules="rules"
                >
                  <el-form-item :label="'原材料'" prop="templateName" required>
                    <div style="width: 60%">
                      <el-select
                        v-model="createFeedingForm.templateName"
                        filterable
                        placeholder="请选择"
                        @change="materialChange"
                      >
                        <el-option
                          v-for="item in materialOptions"
                          :key="item.id"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item :label="'理论用量'" prop="feedAmount" required>
                    <div style="width: 60%">
                      <el-input v-model="createFeedingForm.feedAmount">
                        <template slot="append">kg</template></el-input
                      >
                    </div>
                  </el-form-item>

                  <el-form-item :label="'对外释放'" prop="isShow">
                    <div style="width: 60%">
                      <el-switch v-model="createFeedingForm.isShow">
                      </el-switch>
                    </div>
                  </el-form-item>

                  <el-form-item :label="'是否智能称称量'" prop="dzc">
                    <div style="width: 60%">
                      <el-switch v-model="createFeedingForm.dzc"> </el-switch>
                    </div>
                  </el-form-item>

                  <el-form-item
                    :label="'智能称正误差'"
                    prop="max"
                    v-if="createFeedingForm.dzc"
                  >
                    <div style="width: 60%">
                      <el-input v-model="createFeedingForm.max">
                        <template slot="append">g</template>
                      </el-input>
                    </div>
                  </el-form-item>

                  <el-form-item
                    :label="'智能称负误差'"
                    prop="min"
                    v-if="createFeedingForm.dzc"
                  >
                    <div style="width: 60%">
                      <el-input
                        placeholder="请输入内容"
                        v-model="createFeedingForm.min"
                      >
                        <template slot="prepend">-</template>
                        <template slot="append">g</template>
                      </el-input>
                    </div>
                  </el-form-item>

                  <el-form-item
                    :label="'智能称称量次数'"
                    prop="dzcCount"
                    v-if="createFeedingForm.dzc"
                  >
                    <div style="width: 60%">
                      <el-input v-model="createFeedingForm.dzcCount">
                        <template slot="append">次</template>
                      </el-input>
                    </div>
                  </el-form-item>
                </el-form>
                <div style="text-align: right; margin: 0">
                  <el-button
                    size="mini"
                    type="text"
                    @click.stop="
                      diglogPopover = false;
                      createFeedingForm = {
                        templateName: '',
                        accessoriesId: null,
                        accessoriesType: 0,
                        max: 5,
                        min: 5,
                        dzc: true,
                        dzcCount: 1,
                        feedAmount: '',
                        productionLineId: null,
                        belongsTo: null,
                      };
                    "
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="createFeedingItem"
                    >确定</el-button
                  >
                </div>
              </div>
              <el-button
                slot="reference"
                type="success"
                size="medium"
                >新增原材料</el-button
              >
            </el-popover>
          </el-form-item>
          <el-form-item>
            <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="excelPopover"
          >
            <div class="" style="padding: 10px">
              <el-form
                :model="excelForm"
                ref="excelForm"
                label-width="130px"
                class="demo-dForm"
                :rules="rules1"
              >
                <el-form-item label="计划产量" prop="yield">
                  <div style="width: 60%">
                    <el-input v-model.number="excelForm.yield">
                      <template slot="append">kg</template>
                    </el-input>
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click.stop="
                    excelPopover = false;
                    excelForm = {
                      belongTo: null,
                      yield: '',
                    };
                  "
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="exportExcel"
                  >导出</el-button
                >
              </div>
            </div>
            <el-button slot="reference" type="warning" size="medium"
              >导出模板</el-button
            >
          </el-popover>
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
        <el-form :inline="true" :model="feedingPage" class="demo-form-inline">
          <el-col :span="6" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="原材料"
                v-model="feedingPage.keyword"
                clearable
                class="input-with-select"
                @clear="feedingSearch"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-form>
        <div class="df">
          <el-button
            type="primary"
            @click="feedingSearch"
            class="general_bgc general_border cfff"
            style="height: 36px; margin-right: 10px"
            >&emsp;查询&emsp;</el-button
          >
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="diglogPopover"
          >
            <div class="p15" v-if="diglogPopover">
              <el-form
                :model="createFeedingForm"
                ref="createFeedingForm"
                label-width="130px"
                class="demo-dForm"
                :rules="rules"
              >
                <el-form-item :label="'原材料'" prop="templateName" required>
                  <div style="width: 60%">
                    <el-select
                      v-model="createFeedingForm.templateName"
                      filterable
                      placeholder="请选择"
                      @change="materialChange"
                    >
                      <el-option
                        v-for="item in materialOptions"
                        :key="item.id"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item :label="'理论用量'" prop="feedAmount" required>
                  <div style="width: 60%">
                    <el-input v-model="createFeedingForm.feedAmount">
                      <template slot="append">kg</template></el-input
                    >
                  </div>
                </el-form-item>

                <el-form-item :label="'对外释放'" prop="isShow">
                  <div style="width: 60%">
                    <el-switch v-model="createFeedingForm.isShow"> </el-switch>
                  </div>
                </el-form-item>

                <el-form-item :label="'是否智能称称量'" prop="dzc">
                  <div style="width: 60%">
                    <el-switch v-model="createFeedingForm.dzc"> </el-switch>
                  </div>
                </el-form-item>

                <el-form-item
                  :label="'智能称正误差'"
                  prop="max"
                  v-if="createFeedingForm.dzc"
                >
                  <div style="width: 60%">
                    <el-input v-model="createFeedingForm.max">
                      <template slot="append">g</template>
                    </el-input>
                  </div>
                </el-form-item>

                <el-form-item
                  :label="'智能称负误差'"
                  prop="min"
                  v-if="createFeedingForm.dzc"
                >
                  <div style="width: 60%">
                    <el-input
                      placeholder="请输入内容"
                      v-model="createFeedingForm.min"
                    >
                      <template slot="prepend">-</template>
                      <template slot="append">g</template>
                    </el-input>
                  </div>
                </el-form-item>

                <el-form-item
                  :label="'智能称称量次数'"
                  prop="dzcCount"
                  v-if="createFeedingForm.dzc"
                >
                  <div style="width: 60%">
                    <el-input v-model="createFeedingForm.dzcCount">
                      <template slot="append">次</template>
                    </el-input>
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click.stop="
                    diglogPopover = false;
                    createFeedingForm = {
                      templateName: '',
                      accessoriesId: null,
                      accessoriesType: 0,
                      max: 5,
                      min: 5,
                      dzc: true,
                      dzcCount: 1,
                      feedAmount: '',
                      productionLineId: null,
                      belongsTo: null,
                    };
                  "
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="createFeedingItem"
                  >确定</el-button
                >
              </div>
            </div>
            <el-button
              slot="reference"
              type="success"
              style="height: 36px; margin-right: 10px"
              >新增原材料</el-button
            >
          </el-popover>
          <el-popover
            placement="bottom"
            width="500"
            trigger="click"
            v-model="excelPopover"
          >
            <div class="" style="padding: 10px">
              <el-form
                :model="excelForm"
                ref="excelForm"
                label-width="130px"
                class="demo-dForm"
                :rules="rules1"
              >
                <el-form-item label="计划产量" prop="yield">
                  <div style="width: 60%">
                    <el-input v-model.number="excelForm.yield">
                      <template slot="append">kg</template>
                    </el-input>
                  </div>
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: 0">
                <el-button
                  size="mini"
                  type="text"
                  @click.stop="
                    excelPopover = false;
                    excelForm = {
                      belongTo: null,
                      yield: '',
                    };
                  "
                  >取消</el-button
                >
                <el-button type="primary" size="mini" @click="exportExcel"
                  >导出</el-button
                >
              </div>
            </div>
            <el-button slot="reference" type="warning" style="height: 36px"
              >导出模板</el-button
            >
          </el-popover>
        </div>
      </el-row> -->

      <el-table :data="feedingData" border fit height="600">
        <el-table-column
          property="templateName"
          label="原材料"
          width="150"
          align="center"
        ></el-table-column>
        <el-table-column
          property="feedAmount"
          label="理论用量"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="!scope.row.isEdit">{{ scope.row.feedAmount }}kg</div>
            <el-form
              :model="createFeedingForm"
              ref="createFeedingForm0"
              label-width="0px"
              class="demo-dForm"
              v-else
            >
              <el-form-item prop="feedAmount" required>
                <el-input v-model="createFeedingForm.feedAmount">
                  <template slot="append">kg</template>
                </el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          property="batch_number"
          label="对外释放"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="!scope.row.isEdit">
              <el-switch v-model="scope.row.isShow" disabled> </el-switch>
            </div>
            <el-form
              :model="createFeedingForm"
              ref="createFeedingForm1"
              label-width="0px"
              class="demo-dForm"
              v-else
            >
              <el-form-item prop="dzc" required>
                <el-switch v-model="createFeedingForm.isShow"> </el-switch>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          property="batch_number"
          label="是否智能称称量"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="!scope.row.isEdit">
              <el-switch v-model="scope.row.dzc" disabled> </el-switch>
            </div>
            <el-form
              :model="createFeedingForm"
              ref="createFeedingForm1"
              label-width="0px"
              class="demo-dForm"
              v-else
            >
              <el-form-item prop="dzc" required>
                <el-switch v-model="createFeedingForm.dzc"> </el-switch>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          property="max"
          label="智能称正误差"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="!scope.row.isEdit">
              {{ scope.row.max !== null ? scope.row.max : "5" }}g{{
                scope.row.max !== null ? "" : "(系统默认)"
              }}
            </div>
            <el-form
              :model="createFeedingForm"
              ref="createFeedingForm2"
              label-width="0px"
              class="demo-dForm"
              v-else
            >
              <el-form-item prop="max" required>
                <el-input v-model="createFeedingForm.max">
                  <template slot="append">g</template>
                </el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          property="min"
          label="智能称负误差"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="!scope.row.isEdit">
              {{ scope.row.min !== null ? scope.row.min : "-5" }}g{{
                scope.row.min !== null ? "" : "(系统默认)"
              }}
            </div>
            <el-form
              :model="createFeedingForm"
              ref="createFeedingForm3"
              label-width="0px"
              class="demo-dForm"
              v-else
            >
              <el-form-item prop="min" required>
                <el-input v-model="createFeedingForm.min">
                  <template slot="prepend">-</template>
                  <template slot="append">g</template>
                </el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          property="dzcCount"
          label="智能称称量次数"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="!scope.row.isEdit">
              {{ scope.row.dzcCount !== null ? scope.row.dzcCount : "1" }}次{{
                scope.row.dzcCount !== null ? "" : "(系统默认)"
              }}
            </div>
            <el-form
              :model="createFeedingForm"
              ref="createFeedingForm4"
              label-width="0px"
              class="demo-dForm"
              v-else
            >
              <el-form-item prop="dzcCount" required>
                <el-input v-model="createFeedingForm.dzcCount">
                  <template slot="append">次</template>
                </el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <div class="btn-container jcc df">
              <div class="btn-box df">
                <el-button
                  type="text"
                  class="disib"
                  size="mini"
                  v-if="!scope.row.isEdit"
                  @click="feedingEdit(scope.row)"
                  >修改</el-button
                >
                <el-button
                  type="text"
                  class="disib c67c"
                  size="mini"
                  v-else
                  @click="feedingEdit(scope.row)"
                  >完成</el-button
                >
                <el-popover
                  v-if="!scope.row.isEdit"
                  placement="bottom"
                  width="200"
                  trigger="click"
                  v-model="scope.row.isDel"
                >
                  <div class="p15">
                    <div class="tc p10">是否删除该原材料?</div>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="scope.row.isDel = false"
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="delFeeding(scope.row)"
                        >确定</el-button
                      >
                    </div>
                  </div>
                  <el-button
                    slot="reference"
                    type="text"
                    class="disib red"
                    size="mini"
                    >删除</el-button
                  >
                </el-popover>
                <el-button
                  v-else
                  @click="scope.row.isEdit = false"
                  type="text"
                  class="disib"
                  size="mini"
                  >取消</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page disib mt20">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="feedingTotal"
          :current-page="feedingPage.page"
          @current-change="feedingChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="权限管理" :visible.sync="manageVisible" width="35%">
      <div>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedUser"
          @change="handleCheckedCitiesChange"
          size="medium"
        >
          <el-checkbox
            style="width: 20%; margin: 20px"
            v-for="user in userList"
            :label="user.user_id"
            :key="user.user_id"
            :border="true"
            ><div>{{ user.user_name }}</div></el-checkbox
          >
        </el-checkbox-group>
      </div>
      <div>
        <el-button type="primary" round @click="handleSaveUser">保存</el-button>
      </div>
    </el-dialog>
  </el-main>
</template>
<script>
import api from "@/util/extra-api";
import axios from "@/util/api";

export default {
  data() {
    return {
      total: 0,
      createPopover: false,
      diglogPopover: false,
      templateData: [],
      templatePage: {
        id: null,
        keyword: "",
        page: 1,
        limit: 10,
      },
      pLineEditForm: {
        name: "",
        theoreticalOutput: "",
        unit: "",
        categoryType: "productLine",
      },
      cListRule: {
        name: [{ required: true, message: "请输入模板名", trigger: "blur" }],
      },
      rules1: {
        yield: [{ required: true, message: "请填写计划产量", trigger: "blur" }],
      },
      feedingData: [],
      feedingPage: {
        belongTo: null,
        productionLineId: null,
        keyword: "",
        page: 1,
        limit: 10,
      },
      feedingTotal: 0,
      createFeedingForm: {
        templateName: "",
        accessoriesId: null,
        accessoriesType: 0,
        max: 5,
        min: 5,
        dzc: true,
        dzcCount: 1,
        feedAmount: "",
        productionLineId: null,
        belongsTo: null,
        isShow: true,
      },
      materialOptions: [],
      dailogVisible: false,
      rules: {
        templateName: [
          { required: true, message: "请选择原材料", trigger: "blur" },
        ],
        feedAmount: [
          { required: true, message: "请输入理论用量", trigger: "blur" },
        ],
      },
      manageVisible: false,
      isIndeterminate: false,
      checkAll: false,
      userList: [],
      checkedUser: [],
      excelPopover: false,
      excelForm: {
        belongTo: null,
        yield: "",
      },
    };
  },
  props: {
    companyId: Number,
    productionLineId: Number,
  },
  mounted() {
    this.templatePage.id = this.productionLineId;
    this.getTemplateList();
  },

  methods: {
    exportExcel() {
      this.$refs.excelForm.validate((valid) => {
        if (valid) {
          api
            .fileget(
              `/v1/pc/productionLine/exportTemplate?companyId=${this.companyId}`,
              this.excelForm
            )
            .then((res) => {
              this.excelForm = {
                belongTo: null,
                yield: "",
              };
              this.excelPopover = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
      // /v1/pc/productionLine/exportTemplate
    },
    pageChange(e) {
      this.templatePage.page = e;
      this.getTemplateList();
    },
    getTemplateList() {
      api
        .get(
          `/v1/pc/productionLine/mList?companyId=${this.companyId}`,
          this.templatePage
        )
        .then((res) => {
          if (res.code === 200) {
            this.total = res.total;
            this.templateData = res.data.map((v) => {
              return {
                ...v,
                isDel: false,
              };
            });
            this.templateData.forEach((v) => {
              v.status = v.status === 1;
            });
          }
        });
    },
    searchTemplate() {
      this.templatePage.page = 1;
      this.getTemplateList();
    },
    openManagement(row) {
      this.selectedId = row.id;
      axios
        .get("/pc/company-user-iot/getHasIotUser", {
          company_id: this.companyId,
          other_table_name: "more_feeding_template",
          other_table_id: row.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.userList = res.data;
            this.checkedUser = res.data
              .filter((m) => m.tag)
              .map((m) => m.user_id);
            this.manageVisible = true;
          }
        });
    },
    handleCheckedCitiesChange(value) {
      this.checkedUser = value;
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.userList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.userList.length;
    },
    handleCheckAllChange(val) {
      // console.log(val);
      this.checkedUser = val ? this.userList.map((m) => m.user_id) : [];
      this.isIndeterminate = false;
    },
    handleSaveUser() {
      let data = {
        user_id: this.checkedUser,
        company_id: this.companyId,
        other_table_name: "more_feeding_template",
        other_table_id: this.selectedId,
      };
      axios.post("/pc/company-user-iot/save", data).then((res) => {
        if (res.code == 200) {
          this.manageVisible = false;
        }
      });
    },
    createTemplateItem() {
      api
        .post(`/v1/pc/productionLine/mSave?companyId=${this.companyId}`, {
          productionId: this.productionLineId,
          name: this.pLineEditForm.name,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "新增成功",
              type: "success",
            });
            this.createPopover = false;
            this.templatePage.keyword = "";
            this.templatePage.page = 1;
            this.getTemplateList();
          }
        });
    },
    delTemplateItem(row) {
      api
        .post(`/v1/pc/productionLine/mDelete?companyId=${this.companyId}`, {
          id: row.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.templatePage.keyword = "";
            this.templatePage.page = 1;
            this.getTemplateList();
          }
        });
    },
    delHaccpItem(row) {
      api
        .post(`/v1/pc/productionLine/hDelete?companyId=${this.companyId}`, {
          id: row.id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.haccpListPage.keyword = "";
            this.searchHaccpList();
          }
        });
    },
    templateChange(status) {
      api
        .post(
          `/v1/pc/productionLine/mUpdateStatus?companyId=${this.companyId}`,
          {
            id: this.tListId,
            status: status ? 1 : 0,
          }
        )
        .then((res) => {
          if (res.code === 200) {
            this.getTemplateList();
          }
        });
    },
    feedingChange(e) {
      this.feedingPage.page = e;
      this.getFeeding();
    },
    feedingSearch() {
      this.feedingPage.page = 1;
      this.getFeeding();
    },
    getFeeding() {
      api
        .get(
          `/v1/pc/productionLine/fList?companyId=${this.companyId}`,
          this.feedingPage
        )
        .then((res) => {
          if (res.code === 200) {
            this.feedingData = res.data.map((v) => {
              return {
                ...v,
                isEdit: false,
                isDel: false,
              };
            });
            this.feedingTotal = res.total;
          }
        });
    },
    openTemplate(row) {
      this.createFeedingForm.belongsTo = row.id;
      this.feedingPage.belongTo = row.id;
      this.excelForm.belongTo = row.id;
      this.feedingPage.productionLineId = this.productionLineId;
      this.getFeeding();
      api
        .get(`/v1/pc/productionLine/aAll?companyId=${this.companyId}`)
        .then((res) => {
          if (res.code === 200) {
            this.materialOptions = res.data.map((v) => {
              return {
                value: v.accessoriesName,
                label: v.accessoriesName,
                id: v.id,
              };
            });
            this.dailogVisible = true;
          }
        });
    },
    materialChange(e) {
      this.createFeedingForm.accessoriesId = this.materialOptions.filter(
        (v) => v.label === e
      )[0].id;
    },
    createFeedingItem() {
      // createFeedingForm

      this.$refs.createFeedingForm.validate((valid) => {
        if (valid) {
          this.createFeedingForm.productionLineId = this.productionLineId;
          let data = {
            ...this.createFeedingForm,
          };
          if (data.max === "") {
            data.max = 5;
          }
          if (data.min === "") {
            data.min = 5;
          }
          if (data.dzcCount === "") {
            data.dzcCount = 1;
          }
          data.min = data.min * -1;
          api
            .post(
              `/v1/pc/productionLine/fSave?companyId=${this.companyId}`,
              data
            )
            .then((res) => {
              if (res.code === 200) {
                this.diglogPopover = false;
                this.feedingPage.keyword = "";
                this.feedingSearch();
                this.createFeedingForm = {
                  templateName: "",
                  accessoriesId: null,
                  accessoriesType: 0,
                  max: 5,
                  min: 5,
                  dzc: true,
                  dzcCount: 1,
                  feedAmount: "",
                  productionLineId: null,
                  belongsTo: null,
                };
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    delFeeding(row) {
      api
        .post(`/v1/pc/productionLine/fDelete?companyId=${this.companyId}`, {
          id: row.id,
        })
        .then((res) => {
          this.feedingPage.keyword = "";
          this.feedingSearch();
        });
    },
    feedingEdit(row) {
      if (!row.isEdit) {
        this.feedingData.forEach((v) => {
          if (v.isEdit) {
            v.isEdit = false;
          }
        });
        this.createFeedingForm.templateName = row.templateName;
        this.createFeedingForm.accessoriesId = row.accessoriesId;
        this.createFeedingForm.isShow = row.isShow;
        this.createFeedingForm.max = row.max;
        if (!row.min && row.min !== 0) {
          this.createFeedingForm.min = "";
        } else {
          this.createFeedingForm.min = row.min * -1;
        }
        this.createFeedingForm.dzc = row.dzc;
        this.createFeedingForm.dzcCount = row.dzcCount;
        this.createFeedingForm.productionLineId = row.productionLineId;
        this.createFeedingForm.feedAmount = row.feedAmount;
        row.isEdit = true;
      } else {
        let data = {
          ...this.createFeedingForm,
          id: row.id,
        };
        if (data.min) {
          data.min = data.min * -1;
        }
        api
          .post(
            `/v1/pc/productionLine/fUpdate?companyId=${this.companyId}`,
            data
          )
          .then((res) => {
            if (res.code === 200) {
              row.isEdit = false;
              this.feedingSearch();
              this.createFeedingForm = {
                templateName: "",
                accessoriesId: null,
                accessoriesType: 0,
                max: 5,
                min: 5,
                dzc: true,
                dzcCount: 1,
                feedAmount: "",
                productionLineId: null,
                belongsTo: null,
              };
            }
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  .btn-box {
    justify-content: space-around;
    width: 120px;
  }
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}
</style>


