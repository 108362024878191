<template>
  <el-container class="product">
    <el-header class="box add bb el-header df aic">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>食品加工</el-breadcrumb-item>
        <el-breadcrumb-item>
          <span
            @click="
              layer = 0;
              type = 0;
            "
            :class="layer > 0 ? 'click-able' : ''"
            >类型列表</span
          ></el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="layer >= 1"
          ><span
            :class="layer > 1 ? 'click-able' : ''"
            @click="
              layer = 1;
              type = 0;
            "
            >食品加工线</span
          ></el-breadcrumb-item
        >
        <!-- <el-breadcrumb-item v-if="layer >= 2"> -->
        <template v-if="layer >= 2">
          <el-breadcrumb-item v-if="layer === 2 && type === 0"
            >投料模板列表</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="layer === 2 && type === 1"
            >工序模板列表</el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="(layer === 2 && type === 2) || layer === 3">
            <span
              :class="layer > 2 ? 'click-able' : ''"
              @click="
                layer = 2;
                type = 2;
              "
              >批次生产列表</span
            >
          </el-breadcrumb-item>
        </template>

        <template v-if="layer >= 3">
          <el-breadcrumb-item v-if="type === 1"> 工序列表 </el-breadcrumb-item>
          <el-breadcrumb-item v-if="type === 2">绑码</el-breadcrumb-item>
        </template>
      </el-breadcrumb>
      <div>
        
      </div>
    </el-header>
    <classify
      v-if="layer === 0 && companyId"
      :companyId="companyId"
      @layerChange="layerChange"
    />
    <productionLine
      v-if="layer === 1 && categoryId"
      :companyId="companyId"
      :categoryId="categoryId"
      @layerChange="layerChange"
    />
    <templateVue
      v-if="layer === 2 && type === 0 && productionLineId"
      :productionLineId="productionLineId"
      :companyId="companyId"
    />
    <haccpTemplate
      v-if="layer === 2 && type === 1 && productionLineId"
      :productionLineId="productionLineId"
      :companyId="companyId"
    />
    <batch
      v-if="layer === 2 && type === 2 && productionLineId"
      :productionLineId="productionLineId"
      :categoryId="categoryId"
      :companyId="companyId"
      :noProduction="noProduction"
      @layerChange="layerChange"
    />
    <feeding
      v-if="layer === 3 && type === 0 && feedingData && belongsTo"
      :belongsTo="belongsTo"
      :categoryId="categoryId"
      :companyId="companyId"
      :productionLineId="productionLineId"
      :feedingData="feedingData"
      :theoreticalOutput="theoreticalOutput"
      :productionRecordId="productionRecordId"
      @layerChange="layerChange"
    />
    <process
      v-if="layer === 3 && type === 1 && productionRecordId"
      :companyId="companyId"
      :productionLineId="productionLineId"
      :productionRecordId="productionRecordId"
      :mandatory="mandatoryFlag"
    />

    <bind
      v-if="layer === 3 && type === 2 && productionRecordId"
      :companyId="companyId"
      :productionLineId="productionLineId"
      :productionRecordId="productionRecordId"
    />
  </el-container>
</template>

<script>
import { local } from "@/util/util";

import classify from "./components/classify.vue";
import productionLine from "./components/productionLine.vue";
import templateVue from "./components/template.vue";
import haccpTemplate from "./components/haccpTemplate.vue";
import batch from "./components/batch.vue";
import feeding from "./components/feeding.vue";
import process from "./components/process.vue";
import bind from "./components/bind.vue";

export default {
  data() {
    return {
      layer: 0,
      companyId: null,
      categoryId: null,
      productionLineId: null,
      type: 0,
      mandatoryFlag: null,
      feedingData: null,
      theoreticalOutput: null,
      unit: "",
      belongsTo: null,
      productionRecordId: null,
      noProduction: null,
    };
  },
  mounted() {
    this.companyId = Number(local.get("company_id"));
  },
  components: {
    classify,
    productionLine,
    templateVue,
    haccpTemplate,
    batch,
    feeding,
    process,
    bind,
  },
  // watch: {
  //   layer(newVal, oldVal) {
  //     console.log(this.noProduction, "noProduction");
  //   },
  // },
  methods: {
    layerChange(id, layer, type, data) {
      // console.log(layer, type, "切换");

      this.layer = layer;
      switch (layer) {
        case 1:
          {
            this.categoryId = id;
          }
          break;
        case 2:
          {
            this.productionLineId = id;
            this.type = type;
            if (data) {
              this.mandatoryFlag = data.mandatory;
              this.theoreticalOutput = data.theoreticalOutput;
              this.unit = data.unit;
              if (data.noProduction) {
                this.noProduction = data.noProduction;
              } else {
                this.noProduction = null;
              }
            }
          }
          break;
        case 3:
          {
            this.type = type;
            if (type === 0) {
              if (data) {
                // console.log(data, "data");
                this.feedingData = data.data;
                this.productionRecordId = data.productionRecordId;
                this.feedingData.info[0].theoreticalOutput =
                  this.theoreticalOutput;
                this.feedingData.info[0].unit = this.unit;
                this.belongsTo = id;
              }
            } else {
              this.productionRecordId = data.productionRecordId;
              // console.log(this.productionRecordId, this.productionLineId);
            }
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-table__row .el-form-item {
  margin-bottom: 0 !important;
}
.btn-container {
  .btn-box {
    justify-content: space-around;
    width: 120px;
  }
}
.click-able:hover {
  cursor: pointer;
}
/deep/ .el-table__empty-block {
  width: 100% !important;
}

.product {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
  border-radius: 40px;
  overflow: hidden;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
  border-radius: 40px;
  overflow: hidden;
}

.wait-icon-box {
  background-color: #aaa;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}
.indicator-box {
  padding: 12px 4px;
  .indicator {
    width: 30px;
    height: 10px;
    border-radius: 5px;
    background-color: #99a9bf;
    cursor: pointer;
  }
  .indicator-now {
    width: 36px;
    height: 14px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #475669;
  }
}

.update {
  .indicator {
    background-color: #19be6b !important;
  }
  .indicator-now {
    background-color: #18b566 !important;
  }
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}

.form-container {
  padding: 40px;
  height: 100%;
  .form-box {
    height: 100%;
    border-radius: 34px;
    background-color: #fff;
    overflow: hidden;
    .title {
      padding: 20px;
      font-size: 24px;
      font-weight: bold;
      color: #99a9bf;
    }
    .form {
      padding-top: 0;
      padding: 20px;
      height: 90%;
      .form-info {
        text-align: left;
        width: 80%;
        padding-left: 40px;
        .info-box {
          border: 2px solid #475669;
          border-radius: 20px;
          margin-bottom: 20px;
          width: 400px;
        }
      }
    }
  }
}
.p20 {
  padding: 20px;
}
.gooods-dialog {
  /deep/.el-input-group__append {
    width: 80px !important;
  }
}
</style>
