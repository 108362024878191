<template>
  <el-main class="p15 pt0">
    <div class="df" style="justify-content: flex-end; padding: 10px 0">
      <el-button
        style="margin-left: 10px"
        type="info"
        @click="cancleUpdate"
        v-if="feedingData.isUpdate && feedingData.isInput"
        >取消</el-button
      >
      <el-button
        style="margin-left: 10px"
        :type="feedingData.isInput ? 'success' : 'primary'"
        @click="addFeedingDetail"
        >{{ feedingData.isInput ? "提交" : "修改" }}</el-button
      >
      <el-button
        type="warning"
        style="margin-left: 10px"
        v-if="!feedingData.isInput"
        @click="exportExcel"
        >导出</el-button
      >
    </div>
    <el-table
      :data="feedingData.info"
      style="width: 100%"
      border
      :show-header="false"
    >
      <el-table-column prop="name" width="100" align="center">
        <template slot-scope="scope">
          <span style="color: #ccc">生产号:</span>{{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column prop="total" width="100" align="center">
        <template slot-scope="scope">
          <span style="color: #ccc">理论产量:</span
          >{{ scope.row.theoreticalOutput + scope.row.unit }}
        </template>
      </el-table-column>
      <el-table-column prop="num" width="240" align="center">
        <template slot-scope="scope">
          <template v-if="!feedingData.isInput">
            <span style="color: #ccc">产量:</span>{{ scope.row.num }}kg
          </template>
          <el-form v-else :model="feedingData.info[0]" ref="feedingDataInfo">
            <el-form-item label="产量" label-width="120px" prop="num">
              <div style="width: 80%">
                <el-input v-model="feedingData.info[0].num" @input="dataChange">
                  <template slot="append">kg</template>
                </el-input>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="img" width="300" align="center">
        <template>
          <el-upload
            action="#"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="(file) => handleRemove(file, 0)"
            :http-request="
              (params) => {
                uploadSectionFile(params, 0);
              }
            "
            :file-list="feedingData.info[0].images"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 40px"></div>
    <el-table :data="feedingData.detail" style="width: 100%" border>
      <el-table-column label="序号" type="index" width="50" align="center">
      </el-table-column>
      <el-table-column
        prop="templateName"
        label="原料名称"
        width="180"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="投料量"
        prop="feedQuantity"
        width="240"
        align="center"
      >
        <template slot-scope="scope">
          <template v-if="!feedingData.isInput"
            >{{ scope.row.feedQuantity }}kg
          </template>
          <el-form
            v-else
            label-width="0px"
            :model="feedingData.detail[scope.$index]"
            ref="feedingDataDetail"
          >
            <el-form-item prop="feedQuantity">
              <div style="width: 80%">
                <el-input
                  v-model="feedingData.detail[scope.$index].feedQuantity"
                >
                  <template slot="append">kg</template>
                </el-input>
              </div>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="batch" label="批次选择" width="180" align="center">
        <template slot-scope="scope">
          <div class="df">
            <div
              style="flex: 0 0 120px; padding: 0 10px"
              v-if="
                scope.row.goodsBatchNumber && scope.row.goodsBatchNumber.length
              "
            >
              <div
                style="text-align: left"
                v-for="(item, index) in scope.row.goodsBatchNumber"
                :key="`batch-${scope.$index}-${index}`"
              >
                <el-tag style="margin-bottom: 10px" size="mini">{{
                  item.batchNumber
                }}</el-tag>
              </div>
            </div>
            <el-button
              type="text"
              class="disib"
              size="mini"
              @click="getFeedingBatch(scope.row)"
              >选择批次</el-button
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="img" label="图片" width="300" align="center">
        <template slot-scope="scope">
          <el-upload
            action="#"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="(file) => handleRemove(file, 1, scope.$index)"
            :before-upload="isUpload"
            :http-request="
              (params) => {
                uploadSectionFile(params, 1, scope.$index);
              }
            "
            :file-list="feedingData.detail[scope.$index].images"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="批次选择" :visible.sync="batchVisible" width="600px">
      <el-table
        :data="feedingBatchInfo"
        ref="multipleTable"
        style="width: 100%"
        :show-header="false"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="80"> </el-table-column>
        <el-table-column prop="goodsName" width="120"> </el-table-column>
        <el-table-column prop="batchNumber" width="180"> </el-table-column>
        <el-table-column prop="stock" width="180">
          <template slot-scope="scope">
            当前库存:{{ scope.row.stock }}
          </template>
        </el-table-column>
      </el-table>
      <el-button @click="checkFeedingBatch" type="primary" size="small"
        >确认选择</el-button
      >
    </el-dialog>
  </el-main>
</template>

<script>
import api from "@/util/extra-api";
import { local } from "@/util/util";

export default {
  data() {
    return {
      batchVisible: false,
      feedingBatchInfo: [],
    };
  },
  props: {
    companyId: Number,
    productionLineId: Number,
    categoryId: Number,
    feedingData: Object,
    theoreticalOutput: Number,
    belongsTo: Number,
    productionRecordId: Number,
  },
  mounted() {
    // console.log(this.feedingData);
    this.feedingData.info[0].belongsTo = this.belongsTo;
    this.getSingleFeeding();
  },
  methods: {
    cancleUpdate() {
      this.$set(this.feedingData, "isInput", false);
      this.getFeedingDetail(Number(this.finishedTag.finishedTag) + 1)
    },
    exportExcel() {
      api.fileget(
        `/v1/pc/productionLine/exportFeedRecord?companyId=${this.companyId}`,
        {
          belongsTo: this.feedingData.info[0].belongsTo,
          productionRecordId: this.productionRecordId,
          finishTag: Number(this.finishedTag.finishedTag) + 1,
        }
      );
    },
    openSingleFeeding(row) {
      let theoreticalOutput = this.ProductLineData.filter(
        (v) => v.id === this.productionLineId
      )[0].theoreticalOutput;
      let unit = this.ProductLineData.filter(
        (v) => v.id === this.productionLineId
      )[0].unit;

      this.$set(this.feedingData.info[0], "belongsTo", row.id);
      this.$set(
        this.feedingData.info[0],
        "theoreticalOutput",
        theoreticalOutput
      );
      this.$set(this.feedingData.info[0], "unit", unit);

      this.templateVisible = false;
      this.thirdLevelType = 0;
    },
    getFinishedTag(type) {
      api
        .get(`/v1/wx/productionLine/finishedTag`, {
          type,
          productionRecordId: this.productionRecordId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.finishedTag = res.data;
            this.feedingData.isInput =
              !res.data.firstAddOrIsUpdate || res.data.finishedTag === 0;
            this.finishedTag = res.data;
            if (this.finishedTag.firstAddOrIsUpdate) {
              this.getFeedingDetail(Number(this.finishedTag.finishedTag) + 1);
            }
          }
        });
    },
    getSingleFeeding() {
      api
        .get(`/v1/pc/productionLine/single?companyId=${this.companyId}`, {
          productionLineId: this.productionLineId,
          belongsTo: this.feedingData.info[0].belongsTo,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$set(
              this.feedingData,
              "detail",
              res.data.map((v) => {
                let data = {
                  ...v,
                };
                data.feedQuantity = "";
                if (data.images && JSON.parse(data.images).length) {
                  data.images = JSON.parse(data.images).map((v) => {
                    return { url: v };
                  });
                } else {
                  data.images = [];
                }
                data.goodsBatchNumber = null;
                return data;
              })
            );
          }
          this.getFinishedTag(1);
        });
    },
    handleRemove(file, type, index) {
      if (!type) {
        this.$set(
          this.feedingData.info[0],
          "images",
          this.feedingData.info[0].images.filter((v) => v.name !== file.name)
        );
      } else {
        this.$set(
          this.feedingData.detail[index],
          "images",
          this.feedingData.detail[index].images.filter(
            (v) => v.name !== file.name
          )
        );
      }
    },
    handlePictureCardPreview(file) {
      //查看图片
      // console.log(file, 1);
      window.open(file.url);
    },
    pictureCardPreview(url) {
      window.open(url);
    },
    uploadSectionFile(params, type, index) {
      const file = params.file,
        fileType = file.type,
        isImage = fileType.indexOf("image") != -1,
        isLt10M = file.size / 1024 / 1024 < 10;
      // 这里常规检验，看项目需求而定
      if (!isImage) {
        this.$message.error("只能上传图片格式png、jpg、gif!");
        return;
      }
      if (!isLt10M) {
        this.$message.error("只能上传图片大小小于10M");
        return;
      }
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      fileFormData.append("company_id", this.companyId);
      fileFormData.append(
        "user_id",
        local.get("user") && local.get("user").user_id
      );
      axios.uploadFile("/pc/file/common", fileFormData).then((resp) => {
        if (resp.code != 200) {
          this.$message.error("图片上传失败，请重新上传");
        } else {
          if (!type) {
            this.feedingData.info[0].images.push({
              url: resp.url,
              name: resp.name,
            });
          } else {
            this.feedingData.detail[index].images.push({
              url: resp.url,
              name: resp.name,
            });
          }
        }
      });
    },
    dataChange(e) {
      if (!isNaN(Number(e))) {
        this.feedingData.detail.forEach((v, i) => {
          this.$set(
            this.feedingData.detail[i],
            "feedQuantity",
            Number((v.feedRatio * Number(e)).toFixed(4))
          );
        });
      }
    },
    getFeedingDetail(finishTag) {
      api
        .get(
          `/v1/pc/productionLine/rFeedingDetail?companyId=${this.companyId}`,
          {
            productionRecordId: this.productionRecordId,
            belongsTo: this.feedingData.info[0].belongsTo,
            finishTag,
          }
        )
        .then((res) => {
          if (res.code === 200) {
            this.feedingData.detail.forEach((v, i) => {
              let data = res.data.filter((item) => item.templateId === v.id);
              // console.log(data, v.id, i);
              if (data.length) {
                if (data[0].feedQuantity !== null) {
                  data[0].feedQuantity = data[0].feedQuantity.toFixed(4);
                }
                data[0].images = JSON.parse(data[0].picUrl);
                this.$set(this.feedingData.detail, i, data[0]);
              }
            });
            // console.log(res.data, "报错");
            if (res.data.length) {
              if (
                res.data[res.data.length - 1] &&
                res.data[res.data.length - 1].yield
              ) {
                this.feedingTotalInfo = res.data[res.data.length - 1];
                this.$set(
                  this.feedingData.info[0],
                  "num",
                  res.data[res.data.length - 1].yield
                );
              }
            }
            if (res.data.length) {
              this.$set(this.feedingData, "isUpdate", true);
              this.$set(this.feedingData, "isInput", false);
            }
            if (!this.feedingData.isInput) {
              this.$set(
                this.feedingData,
                "detail",
                this.feedingData.detail.filter((v) => v.feedQuantity)
              );
            }
            // console.log(this.feedingData, 321);
          }
        });
    },
    getFeedingBatch(row) {
      this.accessoriesId = row.accessoriesId;
      api
        .get(
          `/v1/pc/productionLine/checkTheInventory?companyId=${this.companyId}`,
          {
            accessoriesId: row.accessoriesId,
          }
        )
        .then((res) => {
          if (res.code === 200) {
            this.feedingBatchInfo = res.data.map((v) => {
              return {
                ...v,
                accessoriesId: row.accessoriesId,
              };
            });
            this.batchVisible = true;
          }
        });
    },
    checkFeedingBatch() {
      this.feedingData.detail.forEach((v, i) => {
        if (v.accessoriesId === this.feedingBatchInfo[0].accessoriesId) {
          this.$set(
            this.feedingData.detail[i],
            "goodsBatchNumber",
            this.feedingBatchChecked
          );
        }
      });
      this.batchVisible = false;
    },
    handleSelectionChange(val) {
      // console.log(val);
      this.feedingBatchChecked = val.map((v) => {
        return {
          gtin: v.gtin,
          batchNumber: v.batchNumber,
        };
      });
    },
    addFeedingDetail() {
      if (this.feedingData.isInput) {
        let data = [];
        if (
          this.finishedTag.firstAddOrIsUpdate &&
          this.finishedTag.finishedTag !== 0
        ) {
          this.feedingData.detail.forEach((v) => {
            let detail = {
              ...v,
            };
            detail.finishedTag = Number(this.finishedTag.finishedTag) + 1;
            detail.picUrl = JSON.stringify(v.images);
            data.push(detail);
          });
          let total = {
            ...this.feedingTotalInfo,
          };
          total.yield = Number(this.feedingData.info[0].num);
          total.picUrl = JSON.stringify(this.feedingData.info[0].images);
          total.finishedTag = Number(this.finishedTag.finishedTag) + 1;
          data.push(total);
        } else {
          this.feedingData.detail.forEach((v) => {
            let detail = {
              templateId: v.id, //模板的id
              templateName: v.templateName, //模板的名称
              picUrl: JSON.stringify(v.images), //实景图片
              productionRecordId: this.productionRecordId, //生产记录id
              recordType: null, //类型
              yield: null, //总投料
              coordinate: "0,0",
              accessoriesType: v.accessoriesType,
              feedRatio: v.feedRatio,
              feedQuantity: v.feedQuantity, //投料量
              goodsBatchNumber: v.goodsBatchNumber,
              belongsTo: Number(this.feedingData.info[0].belongsTo),
              finishedTag: Number(this.finishedTag.finishedTag) + 1,
            };
            data.push(detail);
          });
          let total = {
            templateId: -1,
            templateName: "总投料",
            picUrl: JSON.stringify(this.feedingData.info[0].images),
            coordinate: "0,0",
            productionRecordId: this.productionRecordId,
            belongsTo: Number(this.feedingData.info[0].belongsTo),
            recordType: null,
            yield: Number(this.feedingData.info[0].num),
            accessoriesType: 0,
            feedRatio: null,
            feedQuantity: null,
            finishedTag: Number(this.finishedTag.finishedTag) + 1,
          };
          data.push(total);
        }
        api
          .post(
            `/v1/pc/productionLine/rAddFeedingDetail?companyId=${this.companyId}`,
            {
              list: data,
            }
          )
          .then((res) => {
            if (res.code === 200) {
              this.feedingData.isInput = false;
            }
          });
      } else {
        this.feedingData.isInput = true;
      }
    },
    isUpload() {
      if (!this.feedingData.isInput) {
        this.feedingData.isInput = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  .btn-box {
    justify-content: space-around;
    width: 120px;
  }
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}
</style>




