<template>
  <el-main class="p15 pt0">
    <div class="pt20" style="text-align: left">
      <el-form
        :inline="true"
        :model="batchPage"
        class="demo-form-inline"
        ref="batchPageCreate"
      >
        <el-form-item>
          <el-input
            placeholder="工序名称"
            v-model="batchPage.name"
            clearable
            @clear="searchBatchList"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchBatchList" size="medium"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="medium" @click="creataBatchItem"
            >新增</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="" style="text-align: left" v-if="noProduction">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-date-picker
            v-model="exportTime"
            type="daterange"
            range-separator="至"
            start-placeholder="导出开始日期"
            end-placeholder="导出结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="handleExport"
            >导出库存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-row class="box search mt20">
      <el-form
        :inline="true"
        :model="batchPage"
        ref="batchPageCreate"
        class="demo-form-inline"
      >
        <el-col :span="4" class="mr10">
          <el-form-item size="medium">
            <el-input
              placeholder="工序名称"
              v-model="batchPage.name"
              clearable
              class="input-with-select"
              @clear="searchBatchList"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5" class="mr10">
          <el-form-item size="medium">
            <el-date-picker
              v-model="exportTime"
              type="daterange"
              range-separator="至"
              start-placeholder="导出开始日期"
              end-placeholder="导出结束日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <div class="df">
          <el-button
            type="primary"
            @click="searchBatchList"
            class="general_bgc general_border cfff"
            style="height: 36px; margin-right: 10px; margin-left: 0px"
            >查询</el-button
          >
          <el-button
            type="success"
            style="height: 36px; margin-right: 10px; margin-left: 0px"
            @click="creataBatchItem"
            >新增</el-button
          >

          <el-button
            v-if="noProduction"
            type="primary"
            style="height: 36px; margin-right: 10px; margin-left: 0px"
            class="general_bgc general_border cfff"
            @click="handleExport"
            >导出库存</el-button
          >
        </div>
      </el-form>
    </el-row> -->
    <el-table :data="batchList" style="width: 100%" border>
      <el-table-column label="序号" type="index" width="50" align="center">
      </el-table-column>
      <el-table-column
        prop="name"
        label="内部生产序号"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          <div v-if="!scope.row.isEdit">
            {{ scope.row.name }}
          </div>
          <div v-else>
            <el-form
              :model="batchEditForm"
              ref="batchEditForm"
              label-width="0px"
              class="demo-dForm"
              :rules="cListRule"
            >
              <el-form-item prop="orderNumber" required>
                <el-input v-model="batchEditForm.orderNumber"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="noProduction"
        prop="virtualStock"
        label="当前库存(kg)"
        width="180"
        align="center"
      >
      </el-table-column>

      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.createTime.replace("T", " ") }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        :width="noProduction ? '280' : '240'"
        align="center"
      >
        <template slot-scope="scope">
          <div class="btn-container jcc df">
            <div class="btn-box df" style="width: 200px">
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="openFeedingDailog(scope.row)"
                >投料记录</el-button
              >
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="gotoNextLayer(scope.row.id, 3, 1)"
                >工序记录</el-button
              >
              <el-button
                type="text"
                class="disib"
                size="mini"
                @click="gotoNextLayer(scope.row.id, 3, 2)"
                >绑码</el-button
              >
              <el-button
                v-if="noProduction"
                type="text"
                class="disib"
                size="mini"
                @click="openVirtualRecord(scope.row)"
                >库存日志</el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <div class="btn-container jcc df">
            <div class="btn-box df">
              <el-button
                type="text"
                class="disib"
                size="mini"
                v-if="!scope.row.isEdit"
                @click="editBatch(scope.row)"
                >修改</el-button
              >
              <template v-else>
                <el-button
                  type="text"
                  class="disib c67c"
                  size="mini"
                  @click="batchUpdate(scope.row)"
                  >完成</el-button
                >
                <el-button
                  type="text"
                  class="'disib"
                  size="mini"
                  @click="
                    scope.row.isEdit = false;
                    batchEditForm = {
                      orderNumber: '',
                    };
                  "
                  >取消</el-button
                >
              </template>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="i-page disib mt20">
      <el-pagination
        background
        :page-size="10"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="batchPage.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="模板选择" :visible.sync="templateVisible" width="300px">
      <el-table :data="templateData" style="width: 100%" :show-header="false">
        <el-table-column prop="name" width="180"> </el-table-column>
        <el-table-column width="80">
          <template slot-scope="scope">
            <el-button
              slot="reference"
              type="text"
              class="disib"
              size="mini"
              @click="openSingleFeeding(scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page disib mt20">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="dialogTotal"
          :current-page="templatePage.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="库存记录" :visible.sync="recordVisible" width="800px">
      <el-table :data="virtualRecordList" style="width: 100%">
        <el-table-column prop="createdName" label="操作人" width="180">
        </el-table-column>
        <el-table-column prop="createdTime" label="操作时间" width="180">
        </el-table-column>
        <el-table-column width="120" label="修改后库存(kg)" prop="newStock">
        </el-table-column>
        <el-table-column width="120" label="库存变化(kg)">
          <template slot-scope="scope">
            <span
              :style="
                scope.row.newStock - scope.row.oldStock > 0
                  ? 'color:#67C23A'
                  : 'color:#F56C6C'
              "
            >
              {{ scope.row.newStock - scope.row.oldStock }}</span
            >
          </template>
        </el-table-column>
        <el-table-column width="120" label="操作">
          <template slot-scope="scope">
            {{
              [
                "手动修正",
                "手动修正",
                "投料自增",
                "绑码自扣",
                "投料扣减",
                "修改还原",
                "批次终结",
              ][scope.row.type]
            }}
          </template>
        </el-table-column>
      </el-table>
      <div class="i-page disib mt20">
        <el-pagination
          background
          :page-size="recordQuery.limit"
          layout="total,prev, pager, next"
          :total="virtualRecordTotal"
          :current-page="recordQuery.page"
          @current-change="recordPageChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import api from "@/util/extra-api";
import dayjs from "dayjs";

export default {
  data() {
    return {
      batchPage: {
        productionLineId: null,
        name: "",
        page: 1,
        limit: 10,
      },
      batchEditForm: {
        orderNumber: "",
      },
      batchList: [],
      total: 0,
      cListRule: {
        orderNumber: [
          { required: true, message: "请输入内部生产序号", trigger: "blur" },
        ],
      },
      productionRecordId: null,
      testData: {
        info: [
          {
            name: "",
            images: [],
            num: null,
            theoreticalOutput: null,
            unit: "",
            recordId: null,
            belongsTo: null,
          },
        ],
        detail: [],
        isInput: true,
      },
      templateData: [],
      templatePage: {
        id: null,
        keyword: "",
        page: 1,
        limit: 10,
      },
      templateVisible: false,
      recordQuery: {
        recordId: null,
        page: 1,
        limit: 10,
      },
      recordVisible: false,
      virtualRecordList: [],
      virtualRecordTotal: 0,
      dialogTotal: 0,
      exportTime: "",
    };
  },
  props: {
    companyId: Number,
    productionLineId: Number,
    categoryId: Number,
    noProduction: Boolean,
  },
  mounted() {
    this.getBatchList();
  },
  methods: {
    handleExport() {
      let data = {
        productionLineId: this.productionLineId,
      };
      if (this.exportTime) {
        data.startTime = dayjs(this.exportTime[0]).format("YYYY-MM-DD");
        data.endTime = dayjs(this.exportTime[1]).format("YYYY-MM-DD");
      }
      api.fileget(
        `/v1/pc/productionLine/export?companyId=${this.companyId}`,
        data
      );
    },
    recordPageChange(page) {
      this.recordQuery.page = page;
      this.getRecordList();
    },
    getRecordList() {
      api
        .get(
          `/v1/pc/productionLine/virtualRecord?companyId=${this.companyId}`,
          this.recordQuery
        )
        .then((res) => {
          this.virtualRecordList = res.data;
          this.virtualRecordTotal = res.total;
        });
    },
    openVirtualRecord(row) {
      this.recordQuery.recordId = row.id;
      this.recordVisible = true;
      this.getRecordList();
    },
    gotoNextLayer(id, layer, type) {
      this.$emit("layerChange", id, layer, type, {
        productionRecordId: id,
      });
    },
    openSingleFeeding(row) {
      // console.log(row, this.testData, "data1");

      this.$emit("layerChange", row.id, 3, 0, {
        data: this.testData,
        productionRecordId: this.productionRecordId,
      });
    },
    getTemplateList() {
      api
        .get(
          `/v1/pc/productionLine/mList?companyId=${this.companyId}`,
          this.templatePage
        )
        .then((res) => {
          if (res.code === 200) {
            this.dialogTotal = res.total;
            this.templateData = res.data.map((v) => {
              return {
                ...v,
                isDel: false,
              };
            });
            this.templateData.forEach((v) => {
              v.status = v.status === 1;
            });
          }
        });
    },
    searchTemplate() {
      this.templatePage.page = 1;
      this.getTemplateList();
    },
    openFeedingDailog(row) {
      let data = this.testData.info[0];
      data.name = row.name;
      data.recordId = row.id;
      data.name = row.name;
      // console.log(data, 123);
      this.$set(this.testData, "info", [data]);
      this.templatePage.id = this.productionLineId;
      this.templatePage.keyword = "";
      this.searchTemplate();
      this.templateVisible = true;
      // this.thirdLevelType = 0;
      this.productionRecordId = row.id;
    },
    pageChange(e) {
      if (this.templateVisible) {
        this.templatePage.page = e;
        this.getTemplateList();
      } else {
        this.batchPage.page = e;
        this.getBatchList();
      }
    },
    getBatchList() {
      this.batchPage.productionLineId = this.productionLineId;
      api
        .get(
          `/v1/pc/productionLine/rList?companyId=${this.companyId}`,
          this.batchPage
        )
        .then((res) => {
          if (res.code === 200) {
            this.total = res.total;
            this.batchList = res.data.map((v) => {
              return {
                ...v,
                isEdit: false,
              };
            });
          }
        });
    },
    searchBatchList() {
      this.batchPage.page = 1;
      console.log(this.batchPage, "搜索");
      this.getBatchList();
    },
    editBatch(row) {
      this.batchList.forEach((v) => {
        if (v.isEdit) {
          v.isEdit = false;
        }
      });
      row.isEdit = true;
      this.batchEditForm.orderNumber = row.name;
    },
    batchUpdate(row) {
      this.$refs.batchEditForm.validate((valid) => {
        if (valid) {
          api
            .post(`/v1/pc/productionLine/rUpdate?companyId=${this.companyId}`, {
              id: row.id,
              productionLineId: this.productionLineId,
              name: this.batchEditForm.orderNumber,
            })
            .then((res) => {
              if (res.code === 200) {
                row.isEdit = false;
                this.getBatchList();
                this.batchEditForm.orderNumber = "";
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    creataBatchItem() {
      api
        .post(`/v1/pc/productionLine/rSave?companyId=${this.companyId}`, {
          productionLineId: this.productionLineId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.searchBatchList();
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-container {
  .btn-box {
    justify-content: space-around;
    width: 120px;
  }
}
.aic {
  align-items: center;
}
.jcc {
  justify-content: center;
}
</style>



